import React, { useContext, useEffect, useState } from 'react';
import { sortBy } from 'lodash';
import { Button, Table, Modal } from '@amzn/awsui-components-react-v3';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import shortUUID from 'short-uuid';

import { preloadLab, endLabs } from 'modules/api';
import messages from './IngressTable.messages';
import { messages as studentRosterTableMessages } from '../studentRosterTable/StudentRosterTable.messages';
import './IngressTable.scss';
import {
    allAlertBannerItems,
    computeTableItems,
    getLabStatus,
    hiddenAlertBannerItems,
    isConsoleReady,
    LabStatus,
} from './IngressTable.utils';
import IngressTableHeader from '../ingressTableHeader/IngressTableHeader';
import { ClassroomContext } from 'data/ClassroomContext';
import { nowInUnixTimeInSec } from 'utils/timestamp-utils';
import EndLabsModal from 'components/endLabsModal/EndLabsModal';
import { removeCourseNameFromLabTitle, useFlags } from 'utils';

const {
    tableHeaderLabStatus,
    tableHeaderStudent,
    tablePreloadingLabStatus,
    tablePreloadedLabStatus,
    tableEndedLabStatus,
    tableFailedLabStatus,
    studentLabEnded,
    cannotIngress,
    close,
    tableEmpty,
    closeModalLabelText,
} = messages;

const IngressTable = ({
    studentTrainings,
    loading,
    trainingLoading,
    trainingRefetch,
    labId,
    langLocale,
    hasStudentRoster,
    setAlertBannerItems,
    setResetRegionBannerItems,
    globals = window,
}) => {
    const { formatMessage } = useIntl();
    const flags = useFlags();
    const { classroomId, labNumber } = useParams();
    const {
        classData: { classroom, content, course },
    } = useContext(ClassroomContext);
    const [isSending, setIsSending] = useState(false);
    const [isError, setError] = useState(false);
    const [batchId, batchIdSet] = useState();
    const [selectedItems, selectedItemsSet] = useState([]);
    const [showEndLabsModal, showEndLabsModalSet] = useState(false);

    const {
        successAlertBannerItems,
        errorAlertBannerItems,
        inProgressAlertBannerItems,
        hasNotStartedAlertBannerItems,
    } = allAlertBannerItems(formatMessage, setAlertBannerItems);

    useEffect(() => {
        if (batchId && !batchId.includes(labId)) {
            batchIdSet(null);
        }
    }, [labId, batchId]);

    useEffect(() => {
        if (classroom.startsOn > nowInUnixTimeInSec()) {
            setAlertBannerItems(hasNotStartedAlertBannerItems);
        } else if (
            batchId &&
            studentTrainings.some(
                training =>
                    training.arn === labId &&
                    isConsoleReady(training?.metaData?.labStatus) &&
                    training?.requestClientToken?.includes(batchId)
            )
        ) {
            setAlertBannerItems(successAlertBannerItems);
        } else if (batchId) {
            setAlertBannerItems(inProgressAlertBannerItems);
        } else {
            setAlertBannerItems(hiddenAlertBannerItems);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [labId, JSON.stringify(studentTrainings), batchId]);

    useEffect(() => {
        selectedItemsSet([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [labId, JSON.stringify(studentTrainings)]);

    const performBatchProvisioning = async preferredRegions => {
        if (isSending) return;
        setIsSending(true);
        try {
            const result = await preloadLab({
                classroomId,
                blueprintArn: labId,
                clientToken: shortUUID.generate(),
                preferredLangLocale: langLocale,
                targetRegions: preferredRegions,
                userKeys: flags?.endLabs ? (selectedItems || []).map(s => s.userKey) : undefined,
            });
            batchIdSet(result?.createStudentTrainings?.batchId);
        } catch (error) {
            console.error(error);
            setAlertBannerItems(errorAlertBannerItems);
        } finally {
            setIsSending(false);
        }
    };

    const performEndLabsCall = async () => {
        await endLabs({
            blueprintArn: labId,
            classroomId,
            clientToken: shortUUID.generate(),
            userKeys: (selectedItems || []).map(s => s.userKey),
        });
        showEndLabsModalSet(false);
        setTimeout(trainingRefetch, 2000);
    };

    const formatLabStatus = status => {
        switch (status) {
            case LabStatus.Preloading:
                return formatMessage(tablePreloadingLabStatus);
            case LabStatus.Preloaded:
                return (
                    <span className="preloaded-lab-status">
                        {formatMessage(tablePreloadedLabStatus)}
                    </span>
                );
            case LabStatus.Ended:
                return formatMessage(tableEndedLabStatus);
            case LabStatus.Failed:
                return (
                    <span className="failed-lab-status">{formatMessage(tableFailedLabStatus)}</span>
                );
            case LabStatus.NoLab:
            // fall through
            default:
                return '-';
        }
    };

    const columnDefinitions = [
        {
            id: 'student',
            header: formatMessage(tableHeaderStudent),
            cell: ({ studentNumber }) => studentNumber || '-',
        },
        hasStudentRoster && {
            id: 'firstName',
            header: formatMessage(studentRosterTableMessages.headerFirstName),
            cell: ({ firstName }) => firstName || '-',
        },
        hasStudentRoster && {
            id: 'lastName',
            header: formatMessage(studentRosterTableMessages.headerLastName),
            cell: ({ lastName }) => lastName || '-',
        },
        {
            id: 'labRegion',
            header: formatMessage(studentRosterTableMessages.headerLabRegion),
            cell: ({ labRegion }) => labRegion || '-',
        },
        {
            id: 'labStatus',
            header: formatMessage(tableHeaderLabStatus),
            cell: lab => formatLabStatus(getLabStatus(lab)),
        },
    ].filter(columnDefinition => columnDefinition);

    const tableItems = sortBy(
        computeTableItems(studentTrainings, labId).map((item, index) => {
            const studentNumber = hasStudentRoster ? index + 1 : item.studentNumber;
            return { ...item, studentNumber };
        }),
        ['studentNumber']
    );

    const onSelectionChange = ({ detail }) => {
        selectedItemsSet(detail.selectedItems);
    };
    const sanitizedLabTitle = removeCourseNameFromLabTitle(
        content[parseInt(labNumber) - 1]?.title,
        course?.title
    );
    return (
        <>
            <Modal
                visible={isError}
                onDismiss={() => setError(false)}
                header={formatMessage(cannotIngress)}
                closeLabel={formatMessage(closeModalLabelText)}
                footer={
                    <span className="awsui-util-f-r">
                        <Button onClick={() => setError(false)} variant="primary">
                            {formatMessage(close)}
                        </Button>
                    </span>
                }
            >
                {formatMessage(studentLabEnded)}
            </Modal>
            <EndLabsModal
                visible={showEndLabsModal}
                onCancel={() => showEndLabsModalSet(false)}
                labTitle={sanitizedLabTitle}
                endDate={classroom.endsOn}
                onConfirm={performEndLabsCall}
                numberOfStudents={selectedItems?.length}
                classCapacity={classroom?.classCapacity}
            />
            <Table
                selectionType={flags?.endLabs ? 'multi' : 'single'}
                selectedItems={selectedItems}
                onSelectionChange={onSelectionChange}
                loading={loading}
                trackBy={'userKey'}
                stickyHeader
                header={
                    <IngressTableHeader
                        {...{
                            isSending,
                            performBatchProvisioning,
                            performEndLab: () => showEndLabsModalSet(true),
                            trainingLoading,
                            trainingRefetch,
                            errorSet: setError,
                            isSendingSet: setIsSending,
                            selectedItems,
                            globals,
                            tableItems,
                            setResetRegionBannerItems,
                        }}
                    />
                }
                columnDefinitions={columnDefinitions}
                items={tableItems}
                empty={
                    <div className="awsui-util-t-c">
                        <div className="awsui-util-pt-s awsui-util-mb-xs">
                            <b>{formatMessage(tableEmpty)}</b>
                        </div>
                    </div>
                }
            ></Table>
        </>
    );
};

export default IngressTable;
