import { useEffect, useMemo, useRef } from 'react';
import debounce from 'lodash/debounce';
import { Box, ColumnLayout, Container, TextContent } from '@amzn/awsui-components-react-v3';
import { FormattedDate, useIntl } from 'react-intl';
import moment from 'moment';

import { JamHeader } from 'components/jamHeader/JamHeader';
import { WidgetContainer, JamStatusWidget, LinkWidget } from 'components';
import { toDetailPageTimeFormat } from 'utils';
import parentMessages from 'containers/classPages/ClassDetailPage/ClassDetailPage.messages';
import { messages } from './JamDetailsSection.messages';
import './JamDetailsSection.scss';

const { dateLabel, timeLabel } = parentMessages;

const {
    teamSizeLabel,
    teamSizeValueFormat,
    liveEventUrlLabel,
    liveEventUrlDescription,
    testEventUrlLabel,
    testEventUrlDescription,
    adminConsoleUrlLabel,
} = messages;

const TIMEOUT_DELAY = 5000;
const DEBOUNCE_DELAY = 4000;

const JamDetailsSection = ({ jamTrainings, classroomRefetch = () => {} }) => {
    const { formatMessage } = useIntl();

    let firstValidTraining = jamTrainings?.find(training => training?.trainingId);

    const timeoutId = useRef(null);

    const debounceRefetch = useMemo(() => debounce(classroomRefetch, DEBOUNCE_DELAY), [
        classroomRefetch,
    ]);

    useEffect(() => {
        return () => {
            timeoutId.current && clearTimeout(timeoutId.current);
        };
    }, []);

    if (!firstValidTraining) {
        return null;
    }
    const {
        eventStatus,
        startsOn,
        endsOn,
        timezone,
        maxTeamSize,
        eventURL,
        testEventURL,
        adminEventURL,
    } = firstValidTraining.metaData;

    if (firstValidTraining.fulfillmentStatus === 'PENDING') {
        timeoutId.current = setTimeout(debounceRefetch, TIMEOUT_DELAY);
    } else {
        clearTimeout(timeoutId.current);
    }

    const mergedDisplayStatus = eventStatus || firstValidTraining.fulfillmentStatus;

    return (
        <Box margin={{ bottom: 'l' }} data-testid="class-details-jam-section">
            <Container header={<JamHeader showDescription={false} />}>
                <div className="jam-detail__container">
                    <ColumnLayout columns={4} borders="all">
                        <WidgetContainer title={formatMessage(dateLabel)}>
                            <FormattedDate
                                value={moment.unix(startsOn).valueOf()}
                                timeZone={timezone}
                            />
                        </WidgetContainer>
                        <WidgetContainer title={formatMessage(timeLabel)}>
                            {[startsOn, endsOn]
                                .map(value => toDetailPageTimeFormat(value, timezone))
                                .join(' - ')}
                        </WidgetContainer>
                        <WidgetContainer title={formatMessage(teamSizeLabel)}>
                            {formatMessage(teamSizeValueFormat, { num: maxTeamSize })}
                        </WidgetContainer>
                        <JamStatusWidget status={mergedDisplayStatus} />
                    </ColumnLayout>
                    <ColumnLayout columns={1} borders="all">
                        <div className="border-top">
                            <LinkWidget
                                title={
                                    <TextContent>
                                        <b>{formatMessage(liveEventUrlLabel)}</b> -{' '}
                                        <i>{formatMessage(liveEventUrlDescription)}</i>
                                    </TextContent>
                                }
                                copyable
                                url={eventURL}
                            />
                        </div>
                        <LinkWidget
                            title={
                                <TextContent>
                                    <b>{formatMessage(testEventUrlLabel)}</b> -{' '}
                                    <i>{formatMessage(testEventUrlDescription)}</i>
                                </TextContent>
                            }
                            copyable
                            url={testEventURL}
                        />
                        <LinkWidget
                            title={formatMessage(adminConsoleUrlLabel)}
                            external
                            url={adminEventURL}
                        />
                    </ColumnLayout>
                </div>
            </Container>
        </Box>
    );
};

export default JamDetailsSection;
