import { useIntl } from 'react-intl';
import WidgetContainer from 'components/widgetContainer/WidgetContainer';
import * as JAM_STATUSES from 'utils/jam-status-definitions';
import messages from './JamStatusWidget.messages';
import { Badge, Spinner } from '@amzn/awsui-components-react-v3';

const {
    jamStatusPending,
    jamStatusUpdating,
    jamStatusNotStarted,
    jamStatusOngoing,
    jamStatusEnded,
    jamStatusCancelled,
    jamStatusError,
    eventStatusLabel,
} = messages;

const getStatusBadgeColor = status => {
    switch (status) {
        case JAM_STATUSES.JAM_EVENT_ONGOING:
            return 'green';
        case JAM_STATUSES.JAM_EVENT_ENDED:
            return 'blue';
        case JAM_STATUSES.JAM_EVENT_ERROR:
            return 'red';
        default:
            return 'grey';
    }
};

const getStatusTranslationMessageBase = formatFn => status => {
    switch (status) {
        case JAM_STATUSES.JAM_EVENT_PENDING:
        case JAM_STATUSES.JAM_EVENT_PENDING_APPROVAL:
            return formatFn(jamStatusPending);
        case JAM_STATUSES.JAM_EVENT_UPDATING:
            return formatFn(jamStatusUpdating);
        case JAM_STATUSES.JAM_EVENT_NOT_STARTED:
            return formatFn(jamStatusNotStarted);
        case JAM_STATUSES.JAM_EVENT_ONGOING:
            return formatFn(jamStatusOngoing);
        case JAM_STATUSES.JAM_EVENT_ENDED:
            return formatFn(jamStatusEnded);
        case JAM_STATUSES.JAM_EVENT_CANCELLED:
            return formatFn(jamStatusCancelled);
        case JAM_STATUSES.JAM_EVENT_ERROR:
            return formatFn(jamStatusError);
        default:
            return '-';
    }
};

/**
 * A widget to display the status of a Jam training.
 * @param status - the Jam's status
 */
const JamStatusWidget = ({ status }) => {
    const { formatMessage } = useIntl();
    const getStatusTranslationMessage = getStatusTranslationMessageBase(formatMessage);
    return (
        <WidgetContainer title={formatMessage(eventStatusLabel)}>
            <>
                <Badge color={getStatusBadgeColor(status)}>
                    {getStatusTranslationMessage(status)}
                </Badge>
                {status === JAM_STATUSES.JAM_EVENT_PENDING ? (
                    <Spinner size="normal" data-testid="jam-spinner" />
                ) : null}
            </>
        </WidgetContainer>
    );
};

export default JamStatusWidget;
