import React from 'react';
import { Modal, Button, Box, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { messages as classFormMessages } from '../classForm/ClassForm.messages';
import { messages } from './ClassDurationWarningModal.messages';
import { useIntl } from 'react-intl';
import { MINIMUM_DURATION_DAYS } from 'components/classForm/ClassForm.utils';

const {
    classDurationWarningBody,
    classDurationWarningHeader,
    classDurationWarningConfirmButton,
} = messages;

const { cancelButtonText } = classFormMessages;

const ModalFooter = ({ onDismiss, onConfirm }) => {
    const { formatMessage } = useIntl();
    return (
        <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
                <Button
                    onClick={onDismiss}
                    data-testid="class-duration-modal__cancel-btn"
                    variant="normal"
                >
                    {formatMessage(cancelButtonText)}
                </Button>
                <Button
                    onClick={onConfirm}
                    data-testid="class-duration-modal__confirm-btn"
                    variant="primary"
                >
                    {formatMessage(classDurationWarningConfirmButton)}
                </Button>
            </SpaceBetween>
        </Box>
    );
};

const ClassDurationWarningModal = ({ isVisible, onDismiss, onConfirm }) => {
    const { formatMessage } = useIntl();
    return (
        <Modal
            data-testid="class-duration-warning"
            visible={!!isVisible}
            onDismiss={onDismiss}
            header={formatMessage(classDurationWarningHeader)}
            footer={<ModalFooter {...{ onConfirm, onDismiss }} />}
        >
            {formatMessage(classDurationWarningBody, {
                num: Math.floor(MINIMUM_DURATION_DAYS / 7),
            })}
        </Modal>
    );
};

export default ClassDurationWarningModal;
