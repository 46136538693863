import React from 'react';
import { Auth } from 'aws-amplify';
import { useIntl } from 'react-intl';
import { ButtonDropdown } from '@amzn/awsui-components-react';
import logo from './logo.svg';

import { sendClientLog } from 'modules/api';
import { supportedLocales } from '../../i18n';
import { useUserInfo, useLocale } from 'utils';
import messages from './Header.messages';
import './Header.css';

const localeSelections = supportedLocales.reduce(
    (accumulator, current) => [
        ...accumulator,
        {
            text: current,
            id: current,
        },
    ],
    []
);

const ClassHeader = () => {
    const { formatMessage } = useIntl();
    const [{ canChangeLanguage, locale }, localeSet] = useLocale();

    const { email } = useUserInfo();

    const onItemClick = async event => {
        if (event.detail.id === 'signout') {
            await Auth.signOut();
        }
    };

    const onLanguageChange = async e => {
        const locale = e.detail.id;

        localeSet({ locale, userSelected: true });

        await sendClientLog({
            type: 'AppClick',
            metrics: { InstructorLanguageSelect: [1, 'Count'] },
            attributes: { locale },
        });
    };

    return (
        <header className="site-header awsui-util-ph-xl awsui-util-pv-m">
            <img src={logo} alt={formatMessage(messages.awsTAndC)} />
            <div>
                {canChangeLanguage ? (
                    <ButtonDropdown items={localeSelections} onItemClick={onLanguageChange}>
                        {locale}
                    </ButtonDropdown>
                ) : null}
                {email ? (
                    <ButtonDropdown
                        items={[
                            {
                                text: email,
                                id: 'email',
                                disabled: true,
                            },
                            {
                                text: formatMessage(messages.signout),
                                id: 'signout',
                            },
                        ]}
                        onItemClick={onItemClick}
                    >
                        {formatMessage(messages.accountDropdown)}
                    </ButtonDropdown>
                ) : null}
            </div>
        </header>
    );
};

export default ClassHeader;
