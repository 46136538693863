import React from 'react';
import { Box } from '@amzn/awsui-components-react-v3';

import { NavigationDropdown } from 'components';
import './SectionHeader.scss';

/**
 *
 * @param {actions} React.Component Extra components to render on the right side that would be like buttons
 * @returns
 */
const SectionHeader = ({ title, variant = 'h2', tagOverride, actions }) => {
    return (
        <header data-testid="section-header">
            <Box variant={variant} data-testid="section-subheader" tagOverride={tagOverride}>
                {title}
            </Box>
            <div>
                {actions}
                <NavigationDropdown />
            </div>
        </header>
    );
};

export default SectionHeader;
