import { useContext } from 'react';
import { useQuery } from 'react-query';

import { useFlags } from 'utils/flags';
import { GilmoreHealthContext } from 'data/GilmoreHealthContext';
import { useProvider } from './ProviderContext';
import { getScheduledEvents } from 'modules';

export const useGilmoreEvents = () => {
    const flags = useFlags();
    const { gilmoreEventDataSet, ...rest } = useContext(GilmoreHealthContext);

    const provider = useProvider();
    const isPartner = provider?.type !== 'DIRECT';
    const checkForScheduledEvents = isPartner && flags?.scheduledEvents;
    const queryFn = () => (checkForScheduledEvents ? getScheduledEvents(provider.arn) : []);
    useQuery([getScheduledEvents, provider], queryFn, {
        refetchOnWindowFocus: true,
        onSuccess: gilmoreEventDataSet,
        onError: console.error,
    });

    return rest;
};
