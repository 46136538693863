import moment from 'moment';
import { COLUMN_IDS, TIME_UNITS } from '../components/classesTableV2/classlistTableConfig';

/**
 * Provider using singleton pattern to generate mock classes only once, using the generated list for any subsequent queryMockClassrooms
 * calls.
 * @type {{readonly allClassrooms: *[]}}
 */
const mockClassroomDataProvider = {
    get allClassrooms() {
        const actualData = generateMockClassrooms(100, 100, 100, 10);

        Object.defineProperty(this, 'allClassrooms', {
            value: actualData,
            writable: false,
            configurable: false,
            enumerable: false,
        });

        return actualData;
    },
};
/**
 * Function simulating querying for classroom list with artificial delay of 2000ms
 * @param operation
 * @param nextToken
 * @param allClassrooms
 * @returns {Promise<*>}
 */
export async function queryMockClassrooms({
    providerArn,
    pageNumber,
    pageSize,
    sortByColumn,
    sortDescending,
    filterOptions,
    fieldsRequestedForAggregation = [],
    simulatedLoadingDelayInMillis = 2000,
}) {
    const allClassrooms = mockClassroomDataProvider.allClassrooms;
    if (simulatedLoadingDelayInMillis > 0) {
        await new Promise(resolve => setTimeout(resolve, 1000));
    }

    const filteredClassrooms = filterBy(allClassrooms, filterOptions);

    const filterValueAggregation = aggregateFilterValues(
        filteredClassrooms,
        fieldsRequestedForAggregation
    );
    sortBy(filteredClassrooms, sortByColumn, sortDescending);
    const firstElementIndex = (pageNumber - 1) * pageSize;
    const classroomList = filteredClassrooms.slice(firstElementIndex, firstElementIndex + pageSize);
    return {
        classroomList,
        hitCount: filteredClassrooms.length,
        isHitCountExact: true,
        filterValueAggregation: filterValueAggregation,
    };
}

/**
 * Function used to sort the list of mock classrooms in memory to simulate service backend.
 */
function sortBy(classesToSort, sortByColumn, isDescending) {
    const stringComparator = (a, b) => a.localeCompare(b);
    const dateComparator = (a, b) => (a.isBefore(b) ? -1 : a.isAfter(b) ? 1 : 0);
    const timeZoneComparator = (locationA, locationB) =>
        stringComparator(locationA.timezone, locationB.timezone);

    const comparators = {
        courseTitle: stringComparator,
        startDate: dateComparator,
        endDate: dateComparator,
        locationData: timeZoneComparator, //timezone
        deliveryMethod: stringComparator,
        classStatus: stringComparator,
        instructor: stringComparator,
    };

    const comparingFunc = comparators[sortByColumn];
    let finalComparingFunc;
    if (isDescending) {
        finalComparingFunc = (a, b) => comparingFunc(a[sortByColumn], b[sortByColumn]) * -1;
    } else {
        finalComparingFunc = (a, b) => comparingFunc(a[sortByColumn], b[sortByColumn]);
    }
    classesToSort.sort(finalComparingFunc);
}

/**
 * logical operator is "or" within an attribute and "and" among the attributes.
 * For example, if filter value is:
 * {
 *     attr1: ["condition1", "condition2"],
 *     attr2: ["condition3", "condition4]
 * }
 * is equivalent to (attr1 == "condition1" or attr1 == "condition2") and (attr2="condition3" or attr="condition4")
 *
 *
 * @param allClasses
 * @param filterValues
 */
function filterBy(allClasses, filterValues) {
    const stringFilter = (attrStringValue, filterValueArray) => {
        for (const filterValue of filterValueArray) {
            if (attrStringValue.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0) {
                return true;
            }
        }
        return false;
    };

    const stringArrayFilter = (attrStringValueArray, filterValueArray) => {
        for (const filterValue of filterValueArray) {
            for (const attrStringValue of attrStringValueArray) {
                if (attrStringValue.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0) {
                    return true;
                }
            }
        }
        return false;
    };

    //Requires dateRage consisting of { before: moment, after: moment}
    const dateFilter = (dateFieldValue, dateRange) => {
        if (dateRange.after) {
            if (dateFieldValue.valueOf() < dateRange.after.valueOf()) {
                return false;
            }
        }
        if (dateRange.before) {
            if (dateFieldValue.valueOf() >= dateRange.before.valueOf()) {
                return false;
            }
        }
        return true;
    };

    const allFilters = {
        [COLUMN_IDS.courseTitle]: stringFilter,
        [COLUMN_IDS.startDate]: dateFilter,
        [COLUMN_IDS.endDate]: dateFilter,
        [COLUMN_IDS.country]: stringFilter,
        [COLUMN_IDS.deliveryMethod]: stringFilter,
        [COLUMN_IDS.instructor]: stringArrayFilter,
        [COLUMN_IDS.createdBy]: stringFilter,
    };

    const filtersToApply = [];
    Object.keys(filterValues).forEach(attributeName => {
        if (
            filterValues[attributeName] &&
            (!Array.isArray(filterValues[attributeName]) || filterValues[attributeName].length > 0)
        ) {
            filtersToApply.push({
                fieldValueRetriever:
                    attributeName === COLUMN_IDS.country
                        ? classroom => classroom['locationData'][attributeName]
                        : classroom => classroom[attributeName],
                filteringFn: attrValue =>
                    allFilters[attributeName](attrValue, filterValues[attributeName]),
            });
        }
    });

    const filteredClasses = allClasses.filter(classroom => {
        for (const filter of filtersToApply) {
            if (!filter.filteringFn(filter.fieldValueRetriever(classroom))) {
                return false;
            }
        }
        return true;
    });

    return filteredClasses;
}

/**
 * returns requested field values aggregated and counted.  For example, if there are 3 classes with the following
 * course title:  class #1: "Advanced Architecting in AWS", class #2: "Beginner in AWS", class #3: "Advanced Architecting in AWS,
 * returns:
 *
 * {
 *      courseName: [
 *          {
 *              value: 'Advanced Architecting in AWS'
 *              count: 2,
 *          },
 *          {
 *              value: 'Beginner in AWS',
 *              count: 1,
 *          }
 *      ],
 *      country: [
 *          ...
 *      ]
 * }
 *
 * @param allClassrooms
 * @param fieldsRequestedForAggregation
 */
function aggregateFilterValues(allClassrooms, fieldsRequestedForAggregation) {
    const fieldAggregationMap = {};
    for (const classroom of allClassrooms) {
        for (const field of fieldsRequestedForAggregation) {
            if (!fieldAggregationMap[field]) {
                fieldAggregationMap[field] = {};
            }
            const valuesForField = fieldAggregationMap[field];
            const fieldValue =
                field === COLUMN_IDS.country ? classroom.locationData[field] : classroom[field];
            const currentCount = valuesForField[fieldValue];
            valuesForField[fieldValue] = currentCount ? currentCount + 1 : 1;
        }
    }

    /**
     * At this point, fieldAggregationMap has the following format:
     * {
     *      fieldName: {
     *          value1: count,
     *          value2: count,
     *          ....
     *      },
     *      //ex
     *      courseName: {
     *          'Advanced Architecting in AWS': 2,
     *          'Super advanced Architecting in AWS': 3,
     *          ...
     *      },
     *      country: {
     *          ...
     *      }
     * }
     */
    const fieldToValueArrayMap = {};
    for (const [attributeName, attributeValuesAggregationMap] of Object.entries(
        fieldAggregationMap
    )) {
        fieldToValueArrayMap[attributeName] = Object.entries(attributeValuesAggregationMap).map(
            entry => {
                return {
                    value: entry[0],
                    count: entry[1],
                };
            }
        );
    }
    return fieldToValueArrayMap;
}

/**
 * Generate classrooms for testing. Randomly generates course titles, startDate and endDate following requirements specified
 * in the parameters.
 * @param numUpcoming Number of mock classes to generate that start in future
 * @param numActive Number of mock classes to generate that are active (i.e. startDate in past, endDate in future)
 * @param numArchived Number of mock classes to generate ended (i.e. endDate is in past)
 * @param numCourses Number of course titles that are random
 */
const generateMockClassrooms = (numUpcoming, numActive, numArchived, numCourses) => {
    const currentMoment = moment();
    const allClassrooms = [];
    //Generate upcoming classrooms
    allClassrooms.push(
        ...generateClassListHelper(
            numUpcoming,
            currentMoment,
            moment(currentMoment).add(1, TIME_UNITS.DAY),
            moment(currentMoment).add(100, TIME_UNITS.DAY),
            moment.duration(1, TIME_UNITS.DAY),
            moment.duration(5, TIME_UNITS.DAY),
            numCourses
        )
    );

    //generate active classrooms
    allClassrooms.push(
        ...generateClassListHelper(
            numActive,
            currentMoment,
            moment(currentMoment).subtract(3, TIME_UNITS.DAY),
            currentMoment,
            moment.duration(4, TIME_UNITS.DAY),
            moment.duration(5, TIME_UNITS.DAY),
            numCourses
        )
    );

    allClassrooms.push(
        ...generateClassListHelper(
            numArchived,
            currentMoment,
            moment(currentMoment).subtract(1, TIME_UNITS.YEAR),
            moment(currentMoment).subtract(3, TIME_UNITS.DAY),
            moment.duration(1, TIME_UNITS.DAY),
            moment.duration(3, TIME_UNITS.DAY),
            numCourses
        )
    );

    //Shuffle the classrooms
    for (let i = allClassrooms.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [allClassrooms[i], allClassrooms[j]] = [allClassrooms[j], allClassrooms[i]];
    }
    return allClassrooms;
};

const DEFAULT_MOCK_CLASSROOM_PROPS = {
    classroomArn: 'arn:aws:learningcontent:us-west-2:classroom/msAUS2JSoNDHeJefWZjyCJ',
    classroomId: 'CLASS#12345',
    courseId:
        'arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-TF-200-ARCHIT-6:6.8.24-725e0c7f',
    courseTitle: 'Advanced Architecting in AWS',
    langLocale: 'en-US',
    timezone: 'America/Detroit',
    country: 'US',
    instructors: ['instructor1@someprovider.com'],
    createdBy: 'coordinator1@someproider.com',
};

const COUNTRY_POOL = ['USA', 'GBR', 'FRA', 'JPN', 'KOR', 'ESP', 'CAN', 'DEU', 'ITA', 'MEX'];

function generateClassListHelper(
    numClasses,
    currentMoment,
    startDateRangeStart,
    startDateRangeEnd,
    endDateMinElapsedFromStartDate,
    endDateMaxElapsedFromStartDate,
    numCourses
) {
    if (!numClasses || numClasses <= 0) {
        return [];
    }
    return Array.from(Array(numClasses)).map(i => {
        const startDate = randomMoment(startDateRangeStart, startDateRangeEnd);
        const endDate = randomMoment(
            moment(startDate).add(endDateMinElapsedFromStartDate.asSeconds(), TIME_UNITS.SECOND),
            moment(startDate).add(endDateMaxElapsedFromStartDate.asSeconds(), TIME_UNITS.SECOND)
        );
        return generateClassroomData({
            ...DEFAULT_MOCK_CLASSROOM_PROPS,
            courseTitle: generateRandomString('Mock AWS Class', numCourses),
            classroomId: `classroom#${i}-${startDate.format()}`,
            startDate,
            endDate,
            createdBy: generateRandomString(
                'mockcoordinator',
                10,
                generateRandomString('@provider', 5, '.com')
            ),
            locationType: Math.random() < 0.5 ? 'physical' : 'virtual',
            instructors: [
                generateRandomString('teacher', 10, generateRandomString('@provider', 5, '.com')),
            ],
            country: COUNTRY_POOL[Math.floor(Math.random() * COUNTRY_POOL.length)],
        });
    });
}

function randomMoment(start, end) {
    return moment(start.valueOf() + Math.floor(Math.random() * (end.valueOf() - start.valueOf())));
}

function generateRandomString(prefix, maxNum, suffix = '') {
    return prefix + Math.floor(Math.random() * maxNum) + suffix;
}

const generateClassroomData = ({
    classroomId,
    startDate,
    endDate,
    courseId,
    courseTitle,
    langLocale,
    locationType,
    timezone,
    country,
    instructors,
    createdBy,
}) => ({
    classroomId,
    startDate,
    endDate,
    langLocale,
    courseId,
    courseTitle,
    instructors,
    createdBy,
    locationData: {
        locationType,
        timezone,
        physicalAddress: {
            country,
        },
    },
});
