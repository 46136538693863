import React, { useEffect, useState, Fragment } from 'react';
import { useIntl, FormattedDate } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import _get from 'lodash/get';
import pick from 'lodash/pick';
import { ColumnLayout, Input, Button } from '@amzn/awsui-components-react';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';
import shortUUID from 'short-uuid';

import {
    Loader,
    AccessCodeTable,
    PageWrapper,
    Breadcrumbs,
    SectionHeader,
    StudentRosterTable,
    StudentRosterModal,
    RemoveStudentModal,
    GilmoreHealthBanner,
    JamDetailsSection,
    WidgetContainer,
} from 'components';
import {
    CLASS_ACCESS_TYPES,
    EMAIL_REGEX,
    NEW_LINE_SPACE_REGEX,
    LOCATION_TYPES,
    getInitialConfigFromStudentListEditState,
} from 'components/classForm/ClassForm.utils';
import {
    getCourseVersionDetails,
    addStudentsToClassroomRoster,
    excludeUser,
    updateClassroom,
    listClassroomStudents,
} from 'modules';
import { useClassroomData } from 'data/useClassroomData';
import messages from './ClassDetailPage.messages';
import {
    getDuration,
    getStudentURL,
    isEmpty,
    paths,
    toDetailPageTimeFormat,
    useFlags,
} from 'utils';

import './ClassDetailPage.css';
import { classDetailPageBreadcrumb, classesPageBreadcrumb } from 'utils/breadcrumbs';
import { ProviderType } from 'containers/providerPages/ProvidersPage';
import CancelClass from 'components/cancelClass/CancelClass';

const Container = ({ children, headerText, description, actionStripe, noGutters = false }) => {
    const baseContainerClass = 'awsui-util-container';
    const containerClass = noGutters
        ? `${baseContainerClass} awsui-util-no-gutters`
        : baseContainerClass;
    return (
        <div className={containerClass}>
            <div className="awsui-util-container-header">
                <div className="awsui-util-action-stripe">
                    <h2>{headerText}</h2>
                    {description ? (
                        <div className="awsui-util-container-header-description">{description}</div>
                    ) : null}
                    {actionStripe ? (
                        <div className="awsui-util-action-stripe-group">{actionStripe}</div>
                    ) : null}
                </div>
            </div>
            <div className="class-detail__container">{children}</div>
        </div>
    );
};

const getAdminLinkFromJamTrainings = jamTrainings => {
    const firstTrainingWithAdminLink = jamTrainings.find(
        training => training?.metaData?.adminEventURL
    );
    if (!firstTrainingWithAdminLink) return null;
    return firstTrainingWithAdminLink.metaData.adminEventURL;
};

const ClassDetailPage = props => {
    const { formatMessage, locale } = useIntl();
    const history = useHistory();
    const {
        match: {
            params: { classroomId },
        },
    } = props;
    const flags = useFlags();
    const {
        classData,
        updateStudentRoster,
        deleteFromStudentRoster,
        updateClassroomData,
        refetch: classroomRefetch,
    } = useClassroomData(classroomId, {
        withConsistencyRetry: true,
        withInstructors: flags.instructorAssignment,
    });
    const location = useLocation();
    const {
        openStudentListModal: openStudentListModalInitialValue,
        studentListValue: studentListValueInitialValue,
    } = loadInitialStudentListConfig(classroomId, location, history);
    const [hasStudentRoster, setHasStudentRoster] = useState(false);
    const [hasAccessCodes, setHasAccessCodes] = useState(false);
    const [courseVersionDetails, courseVersionDetailsSet] = useState({});
    const instructors = classData?.classroom?.instructors || [];
    const [loading, setLoading] = useState(false);
    const [openStudentListModal, setOpenStudentListModal] = useState(
        openStudentListModalInitialValue
    );
    const [studentListValue, setStudentListValue] = useState(studentListValueInitialValue);
    const [studentRosterErrors, setStudentRosterErrors] = useState([]);
    const [showRemoveStudentModal, setShowRemoveStudentModal] = useState(false);
    const [studentToRemoveFromRoster, setStudentToRemoveFromRoster] = useState('');
    const [classCapacitySizeExceededBy, setClassCapacitySizeExceededBy] = useState(0);
    const [gilmoreLicenseError, setGilmoreLicenseError] = useState(false);
    const [showBtnSpinner, setShowBtnSpinner] = useState(false);

    const jamTrainings = classData?.jamTrainings?.trainings || [];

    useEffect(() => {
        const courseArn = _get(classData, 'course.courseId', '');
        if (courseArn) {
            getCourseVersionDetails(courseArn).then(courseVersionDetailsSet);
        }
    }, [classData]);

    useEffect(() => {
        if (classData.classroom) {
            if (
                flags.studentRoster &&
                classData.classroom.accessType === CLASS_ACCESS_TYPES.roster
            ) {
                setHasStudentRoster(true);
            } else {
                setHasStudentRoster(false);
                setHasAccessCodes(true);
            }
        }
    }, [flags.studentRoster, classData.classroom, hasAccessCodes]);

    useEffect(() => {
        setLoading(classData.classroom && classroomId === classData.classroom.classroomId);
    }, [classroomId, classData.classroom]);

    const { virtualUrl, locationType, timezone = moment.tz.guess(), physicalAddress } = _get(
        classData,
        'classroom.locationData',
        {}
    );
    const { addressLine1, addressLine2, city, state, postalCode, country } = physicalAddress || {};

    const startsOn = _get(classData, 'classroom.startsOn', 0);
    const endsOn = _get(classData, 'classroom.endsOn', 0);
    const classCapacity = _get(classData, 'classroom.classCapacity', 0);
    const studentCount = _get(classData, 'classroom.studentCount', 0);
    const trainingProviderName = classData?.classroom?.trainingProviderName;
    const trainingProviderType = classData?.classroom?.trainingProviderType;
    const createdBy = _get(classData, 'classroom.createdBy');
    const editDisabled = moment().isAfter(moment.unix(endsOn));

    const startTime = toDetailPageTimeFormat(startsOn, timezone);
    const endTime = toDetailPageTimeFormat(endsOn, timezone);
    const timeFormatted = `${startTime} - ${endTime}`;

    const removeDuplicateUsers = users => {
        return [...new Map(users.map(user => [user.email, user])).values()];
    };

    const validateCSVList = () => {
        const listErrors = [];
        const users = [];
        studentListValue.split(',').forEach(email => {
            const sanitizedEmail = email.trim();
            if (EMAIL_REGEX.test(sanitizedEmail) && !NEW_LINE_SPACE_REGEX.test(sanitizedEmail)) {
                users.push({ email: sanitizedEmail });
            } else {
                listErrors.push({ email: sanitizedEmail });
            }
        });
        const uniqueUsers = removeDuplicateUsers(users);
        return {
            listErrors,
            users: uniqueUsers,
        };
    };

    const closeAndResetModal = () => {
        setOpenStudentListModal(false);
        setStudentListValue('');
        setStudentRosterErrors([]);
        setClassCapacitySizeExceededBy(0);
        setGilmoreLicenseError(false);
    };

    const addStudentsToRoster = async () => {
        setShowBtnSpinner(true);
        const { listErrors, users } = validateCSVList();
        if (listErrors.length > 0) {
            setStudentRosterErrors(listErrors);
            setShowBtnSpinner(false);
        } else {
            setStudentRosterErrors([]);
            try {
                const {
                    addStudentsToClassroomRoster: addStudentsToRoster,
                } = await addStudentsToClassroomRoster({
                    users,
                    classroomId: classData.classroom.classroomId,
                    providerArn: classData.classroom.providerArn,
                });
                if (addStudentsToRoster.statusCode === 200) {
                    const { classroomId, providerArn } = classData.classroom;
                    const { classroomUsers: newUsers } = await listClassroomStudents(
                        classroomId,
                        providerArn
                    );

                    updateStudentRoster(newUsers);
                    closeAndResetModal();
                }
            } catch (error) {
                const { errors } = JSON.parse(error);
                const { errorCodes } = errors[0];
                if (errorCodes[0].code === 'PropertyError:ClassCapacityAndNewRosterMismatch') {
                    const { studentRoster, classCapacity, studentCount } = classData.classroom;
                    let classSizeExceededBy = 0;
                    const newStudents = users.length;
                    const currentStudents =
                        studentCount > studentRoster.length ? studentCount : studentRoster.length;
                    if (newStudents + currentStudents > classCapacity) {
                        classSizeExceededBy = newStudents + currentStudents - classCapacity;
                    }
                    setClassCapacitySizeExceededBy(classSizeExceededBy);
                }
            } finally {
                setShowBtnSpinner(false);
            }
        }
    };

    const capacityUpdateWithRoster = async () => {
        setShowBtnSpinner(true);
        const { listErrors, users } = validateCSVList();
        if (listErrors.length > 0) {
            setStudentRosterErrors(listErrors);
            setShowBtnSpinner(false);
        } else {
            const studentCount = classData.classroom.studentCount;
            const rosterLength = classData.classroom.studentRoster.length;
            const licensesReserved = studentCount > rosterLength ? studentCount : rosterLength;
            const classCapacity = licensesReserved + users.length;
            const classroom = pick(classData.classroom, [
                'providerArn',
                'langLocale',
                'courseId',
                'startsOn',
                'endsOn',
                'accessType',
            ]);
            const locationData = pick(classData.classroom.locationData, [
                'virtualUrl',
                'timezone',
                'locationType',
            ]);
            try {
                await updateClassroom({
                    classCapacity,
                    classroom: {
                        ...classroom,
                        locationData,
                    },
                    classroomId,
                    clientRequestToken: shortUUID.generate(),
                });
                const {
                    addStudentsToClassroomRoster: addStudentsToRoster,
                } = await addStudentsToClassroomRoster({
                    users,
                    classroomId: classData.classroom.classroomId,
                    providerArn: classData.classroom.providerArn,
                });
                if (addStudentsToRoster.statusCode === 200) {
                    const { classroomId, providerArn } = classData.classroom;
                    const { classroomUsers: newUsers } = await listClassroomStudents(
                        classroomId,
                        providerArn
                    );
                    updateStudentRoster(newUsers);
                    updateClassroomData({ classCapacity });
                    closeAndResetModal();
                }
            } catch (error) {
                const { errors } = JSON.parse(error);
                const { errorCodes } = errors[0];
                if (errorCodes[0].code === 'PropertyError:IncreaseInvalid') {
                    setClassCapacitySizeExceededBy(0);
                    setGilmoreLicenseError(true);
                }
            } finally {
                setShowBtnSpinner(false);
            }
        }
    };

    const removeStudentFromRoster = async email => {
        try {
            const { classroomId, providerArn } = classData.classroom;
            setShowBtnSpinner(true);
            await excludeUser({
                classroomId,
                providerArn,
                user: { email },
                relationship: 'student',
                clientRequestToken: shortUUID.generate(),
            });
            deleteFromStudentRoster(email);
            setShowRemoveStudentModal(false);
        } catch (error) {
            console.log(error);
        } finally {
            setShowBtnSpinner(false);
        }
    };

    const onRemoveStudent = async email => {
        setShowRemoveStudentModal(true);
        setStudentToRemoveFromRoster(email);
    };

    return (
        <>
            <Helmet>
                <title>{formatMessage(messages.pageTitle)}</title>
            </Helmet>
            <Loader isLoading={isEmpty(loading)}>
                <PageWrapper>
                    <GilmoreHealthBanner />
                    <Breadcrumbs
                        items={[
                            classesPageBreadcrumb(formatMessage),
                            classDetailPageBreadcrumb(formatMessage, classroomId),
                        ]}
                    />
                    {trainingProviderName ? (
                        <h1 className="awsui-text-large awsui-util-pb-l">{trainingProviderName}</h1>
                    ) : null}
                    <SectionHeader
                        title={formatMessage(messages.pageTitle)}
                        actions={
                            <>
                                <Button
                                    variant="normal"
                                    className="class-detail__action-btn"
                                    disabled={editDisabled}
                                    label={formatMessage(messages.editButtonLabel)}
                                    onClick={() => history.push(paths.classEditPage(classroomId))}
                                >
                                    {formatMessage(messages.editButtonText)}
                                </Button>
                                <CancelClass
                                    classroomId={classroomId}
                                    providerArn={classData.classroom?.providerArn}
                                    isCancellable={classData.classroom?.isCancellable}
                                    trainingProviderType={classData.classroom?.trainingProviderType}
                                    jamAdminLink={getAdminLinkFromJamTrainings(jamTrainings)}
                                />
                            </>
                        }
                    />
                    <Container headerText={formatMessage(messages.classInformationHeader)}>
                        <ColumnLayout columns={4} borders="vertical">
                            <div data-awsui-column-layout-root="true">
                                <WidgetContainer title={formatMessage(messages.courseTitleLabel)}>
                                    {_get(classData, 'course.title', '')}
                                </WidgetContainer>
                                <WidgetContainer title={formatMessage(messages.courseVersionLabel)}>
                                    {_get(classData, 'course.courseId', '')
                                        .split(':')
                                        .pop()}
                                </WidgetContainer>
                                <WidgetContainer
                                    title={formatMessage(messages.courseContentVersionLabel)}
                                >
                                    {_get(courseVersionDetails, 'contentVersion', '-')}
                                </WidgetContainer>
                                <WidgetContainer
                                    title={formatMessage(messages.courseLanguageLabel)}
                                >
                                    {_get(classData, 'course.langLocale', '')}
                                </WidgetContainer>
                                <WidgetContainer title={formatMessage(messages.dateLabel)}>
                                    <>
                                        <FormattedDate
                                            value={moment.unix(startsOn).valueOf()}
                                            timeZone={timezone}
                                        />{' '}
                                        -{' '}
                                        <FormattedDate
                                            value={moment.unix(endsOn).valueOf()}
                                            timeZone={timezone}
                                        />
                                    </>
                                </WidgetContainer>
                                <WidgetContainer title={formatMessage(messages.timeLabel)}>
                                    {timeFormatted}
                                </WidgetContainer>
                                <WidgetContainer title={formatMessage(messages.durationLabel)}>
                                    {getDuration(startsOn, endsOn, locale)}
                                </WidgetContainer>
                                <WidgetContainer title={formatMessage(messages.timeZoneLabel)}>
                                    {timezone}
                                </WidgetContainer>
                                <WidgetContainer
                                    title={
                                        trainingProviderType === ProviderType.Direct
                                            ? formatMessage(messages.trainingProviderLabel)
                                            : formatMessage(messages.trainingPartnerLabel)
                                    }
                                >
                                    {trainingProviderName}
                                </WidgetContainer>
                                <WidgetContainer
                                    title={formatMessage(messages.classNumberOfStudents)}
                                >
                                    {classCapacity}
                                </WidgetContainer>
                                {createdBy ? (
                                    <WidgetContainer title={formatMessage(messages.classCreatedBy)}>
                                        {createdBy}
                                    </WidgetContainer>
                                ) : null}
                                <WidgetContainer title={formatMessage(messages.classInstructor)}>
                                    {instructors.length ? instructors : '-'}
                                </WidgetContainer>
                            </div>
                        </ColumnLayout>
                    </Container>
                    <Container headerText={formatMessage(messages.classLocationHeader)}>
                        {locationType === LOCATION_TYPES.virtual ? (
                            <Fragment>
                                <WidgetContainer
                                    title={formatMessage(messages.virtualClassUrlLabel)}
                                >
                                    <div className="class-detail__flex-between">
                                        <Button
                                            variant="icon"
                                            icon="external"
                                            label={formatMessage(messages.openVirtualClassUrlLabel)}
                                            href={virtualUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        />
                                        <Input // using input for easier to copy text
                                            label={formatMessage(messages.virtualClassUrlLabel)}
                                            ariaRequired
                                            readonly
                                            value={virtualUrl}
                                        />
                                    </div>
                                </WidgetContainer>
                            </Fragment>
                        ) : null}
                        {locationType === LOCATION_TYPES.physical ? (
                            <WidgetContainer title={formatMessage(messages.classAddressLabel)}>
                                {addressLine1}
                                {addressLine2}
                                <>
                                    {city}, {state} {postalCode}, {country}
                                </>
                            </WidgetContainer>
                        ) : null}
                    </Container>
                    {
                        <AccessCodeTable
                            accessCodes={_get(classData, 'classroom.accessCodes', [])}
                            studentUrl={getStudentURL(classroomId)}
                            hasStudentRoster={hasStudentRoster}
                        />
                    }

                    {flags.studentRoster && hasStudentRoster && (
                        <>
                            <Container
                                headerText={formatMessage(messages.classStudentRosterHeader)}
                                actionStripe={
                                    <Button onClick={() => setOpenStudentListModal(true)}>
                                        {formatMessage(messages.addStudentListButton)}
                                    </Button>
                                }
                                noGutters
                            >
                                <StudentRosterTable
                                    roster={classData.classroom.studentRoster}
                                    onRemoveStudent={onRemoveStudent}
                                />
                            </Container>
                            <StudentRosterModal
                                {...{
                                    openStudentListModal,
                                    closeStudentListModal: closeAndResetModal,
                                    studentListValue,
                                    setStudentListValue,
                                    addStudentsToRoster,
                                    studentRosterErrors,
                                    classCapacity,
                                    classCapacitySizeExceededBy,
                                    setClassCapacitySizeExceededBy,
                                    roster: classData.classroom.studentRoster,
                                    capacityUpdateWithRoster,
                                    gilmoreLicenseError,
                                    setGilmoreLicenseError,
                                    showBtnSpinner,
                                    studentCount,
                                    classroomId,
                                }}
                            />
                            <RemoveStudentModal
                                {...{
                                    showRemoveStudentModal,
                                    setShowRemoveStudentModal,
                                    removeStudentFromRoster,
                                    showBtnSpinner,
                                    email: studentToRemoveFromRoster,
                                }}
                            />
                        </>
                    )}
                    <JamDetailsSection
                        jamTrainings={jamTrainings}
                        classroomRefetch={classroomRefetch}
                    />
                </PageWrapper>
            </Loader>
        </>
    );
};

const loadInitialStudentListConfig = (classroomId, location, history) => {
    const studentListModalConfig = getInitialConfigFromStudentListEditState(classroomId, location);

    if (studentListModalConfig) {
        history.replace({ ...location, state: undefined });
        return studentListModalConfig;
    } else {
        return {
            openStudentListModal: false,
            studentListValue: '',
        };
    }
};

export default ClassDetailPage;
