import { defineMessages } from 'react-intl.macro';

const ingressTableMessages = defineMessages({
    tableEmpty: {
        id: 'ingress.table.empty',
        defaultMessage: 'No trainings to display.',
    },
    tableHeaderStudent: {
        id: 'ingress.header.student',
        defaultMessage: 'Student',
    },
    tableHeaderLabStatus: {
        id: 'ingress.header.labStatus',
        defaultMessage: 'Lab Status',
    },
    tableHeaderRegion: {
        id: 'ingress.header.region',
        defaultMessage: 'Region',
    },
    tableHeaderControls: {
        id: 'ingress.header.controls',
        defaultMessage: 'Access Controls',
    },
    preloadInProgress: {
        id: 'ingress.preload.inProgress',
        defaultMessage:
            'Pre-loading in progress. To view the current status of pre-loaded labs, click the "Refresh" button below.',
    },
    preloadSuccess: {
        id: 'ingress.preload.success',
        defaultMessage: 'Lab pre-load was successful.',
    },
    preloadFail: {
        id: 'ingress.preload.fail',
        defaultMessage:
            'A few labs failed to pre-load. Please try again by pressing the "Pre-load all" button.',
    },
    preloadDismiss: {
        id: 'ingress.preload.dismiss',
        defaultMessage: 'Dismiss',
    },
    preloadClassNotStarted: {
        id: 'ingress.preload.classNotStarted',
        defaultMessage: 'Class has not started. Pre-loading labs is not available',
    },
    ingressControl: {
        id: 'ingress.button.ingress',
        defaultMessage: 'Enter Console',
    },
    cannotIngress: {
        id: 'ingress.modal.cannotIngress',
        defaultMessage: 'Cannot Ingress',
    },
    close: {
        id: 'ingress.modal.studentLabEnded.close',
        defaultMessage: 'Close',
    },
    closeModalLabelText: {
        id: 'ingress.modal.closeLabelText',
        defaultMessage: 'Close dialog',
    },
    studentLabEnded: {
        id: 'ingress.modal.studentLabEnded',
        defaultMessage: "The student's lab has ended",
    },
    ingressingControl: {
        id: 'ingress.button.ingressing',
        defaultMessage: 'Ingressing',
    },
    labTitle: {
        id: 'ingress.lab.title',
        defaultMessage: 'Lab',
    },
    tableReadyLabStatus: {
        id: 'ingress.table.labReady',
        defaultMessage: 'Ready',
    },
    tablePreloadingLabStatus: {
        id: 'ingress.table.labPreloading',
        defaultMessage: 'Preloading in progress',
    },
    tablePreloadedLabStatus: {
        id: 'ingress.table.labPreloaded',
        defaultMessage: 'Preloaded',
    },
    tableEndedLabStatus: {
        id: 'ingress.table.labEnded',
        defaultMessage: 'Ended',
    },
    tableFailedLabStatus: {
        id: 'ingress.table.labFailed',
        defaultMessage: 'Preload failed',
    },
    ingressNoConsole: {
        id: 'ingress.button.noConsole',
        defaultMessage: 'None',
    },
    resetRegion: {
        id: 'ingress.header.resetRegion',
        defaultMessage:
            'Your previously selected region {selectedRegion} is not available for Lab {labNumber}. A supported region has been selected for you.',
    },
});

export default ingressTableMessages;
