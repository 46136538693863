import React from 'react';
import { useIntl } from 'react-intl';
import { Table, TableSorting, TablePagination } from '@amzn/awsui-components-react';
import { getColumnLabelBase } from './AccessCodeTable.utils';
import messages from './AccessCodeTable.messages';
import './AccessCodeTable.css';
import { CopyButton } from '../copyButton/CopyButton';

const COLUMN_ID = {
    accessCode: 'accessCode',
    accessCodeCapacityUsed: 'accessCodeCapacityUsed',
};

const buildColumnDefinitions = (
    formatMessage,
    getColumnLabel,
    studentUrl,
    singleAccessCode,
    hasStudentRoster
) => [
    {
        id: COLUMN_ID.accessCode,
        header: (
            <div>
                <span>
                    {formatMessage(
                        hasStudentRoster
                            ? messages.studentRosterLabel
                            : singleAccessCode
                            ? messages.multiUseCodeLabel
                            : messages.singleAccessCodeLabel
                    )}
                </span>
                &nbsp;&ndash;&nbsp;
                <span className="access-code-description">
                    {formatMessage(messages.accessCodeDescription)}
                </span>
            </div>
        ),
        cell: ({ accessCode }) => {
            const studentAccessUrl = hasStudentRoster
                ? `${studentUrl}`
                : `${studentUrl}?accessCode=${encodeURIComponent(accessCode)}`;
            return (
                <div className="access-code-cell">
                    <a
                        className="access-code-url"
                        href={studentAccessUrl}
                        aria-label={formatMessage(messages.studentAccessUrlLabel)}
                    >
                        {studentAccessUrl}
                    </a>
                    <CopyButton label={messages.copyCodeLabel}>{studentAccessUrl}</CopyButton>
                </div>
            );
        },
    },
    !hasStudentRoster && {
        id: COLUMN_ID.accessCodeCapacityUsed,
        header: formatMessage(messages.useCodeLabel),
        label: singleAccessCode
            ? undefined
            : sortState =>
                  getColumnLabel(
                      sortState,
                      COLUMN_ID.accessCodeCapacityUsed,
                      formatMessage(messages.useCodeLabel)
                  ),
        cell: ({ accessCodeCapacityUsed, accessCodeCapacity }) =>
            `${accessCodeCapacityUsed}/${accessCodeCapacity}`,
    },
];

const AccessCodeTable = ({ accessCodes, studentUrl, hasStudentRoster }) => {
    const { formatMessage } = useIntl();
    const getColumnLabel = getColumnLabelBase(
        formatMessage(messages.sortedAscending),
        formatMessage(messages.sortedDescending)
    );

    if (!accessCodes || accessCodes.length === 0) {
        if (hasStudentRoster) {
            accessCodes = [''];
        } else {
            return null;
        }
    }

    const singleAccessCode = accessCodes?.length === 1;

    const columnDefinitions = buildColumnDefinitions(
        formatMessage,
        getColumnLabel,
        studentUrl,
        singleAccessCode,
        hasStudentRoster
    );

    return (
        <Table
            className="access-codes-table"
            header={
                <h2>
                    {formatMessage(
                        hasStudentRoster ? messages.studentRosterHeader : messages.accessCodeHeader
                    )}
                </h2>
            }
            items={accessCodes}
            features={singleAccessCode ? [] : ['sorting', 'pagination']}
            columnDefinitions={columnDefinitions}
        >
            {singleAccessCode ? null : (
                <TableSorting
                    sortingColumn={COLUMN_ID.accessCodeCapacityUsed}
                    sortingAscending
                    sortableColumns={[
                        { id: COLUMN_ID.accessCodeCapacityUsed, field: 'accessCodeCapacityUsed' },
                    ]}
                />
            )}

            {singleAccessCode ? null : <TablePagination pageSize={5} />}
        </Table>
    );
};

export default AccessCodeTable;
