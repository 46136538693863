import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import _get from 'lodash/get';

import { HeroBanner, Loader, SectionHeader, LabList } from 'components';
import { isEmpty } from 'utils/helpers';
import { useClassroomData } from 'data/useClassroomData';
import messages from './ClassPage.messages';
import './ClassPage.css';

const ClassPage = props => {
    const {
        match: {
            params: { classroomId },
        },
    } = props;

    const { formatMessage } = useIntl();
    const { classData } = useClassroomData(classroomId);
    const classAbstract = _get(classData, 'course.abstract');

    return (
        <Fragment>
            <Helmet>
                <title>{_get(classData, 'course.title')}</title>
            </Helmet>
            <Loader message={formatMessage(messages.loading)} isLoading={isEmpty(classData)}>
                <div className="class-page">
                    <HeroBanner
                        title={_get(classData, 'course.title')}
                        locationType={_get(classData, 'classroom.locationData.locationType')}
                    />
                    <div className="class-page__body">
                        {classAbstract ? (
                            <Fragment>
                                <h2 className="content-header" data-testid="description-header">
                                    {formatMessage(messages.header)}
                                </h2>
                                <p data-testid="abstract">{classAbstract}</p>
                            </Fragment>
                        ) : null}
                        <SectionHeader title={formatMessage(messages.sectionHeading)} />
                        <section>
                            <LabList />
                        </section>
                    </div>
                </div>
            </Loader>
        </Fragment>
    );
};

export default ClassPage;
