import React, { useMemo, useReducer, useState } from 'react';
import {
    Grid,
    Box,
    Flashbar,
    Header,
    Pagination,
    Table,
} from '@amzn/awsui-components-react-v3/polaris';
import { useUserInfo } from 'utils/userInfo';
import { ClassroomListPreferences, getConfiguration } from './classlistTableConfig';
import { messages } from '../classesTable/ClassTable.messages';
import { useIntl } from 'react-intl';
import { useClassroomQuery } from './classroomListingHooks';
import { useProvider } from '../../data/ProviderContext';
import ClassroomListFilter from './ClassroomListFilter';
import { useAcceptInvitations } from '../../utils/useAcceptInvitations';
import { useClasslistingPreference } from './classlistPreferenceManager';
import {
    getClassListingTableEventDispatcher,
    classListingTableStateProcessor,
    getClassListingTableInitialState,
} from './classlistStateManager';

const ClassListingTable = ({ archivedOnly = false }) => {
    const providerArn = useProvider()?.arn;
    const { userIsTrainingCoordinator, isLoading: isLoadingUserInfo } = useUserInfo();
    const intl = useIntl();
    const acceptedInvitations = useAcceptInvitations();
    const { tablePreferences, preferenceUpdater: setTablePreferences } = useClasslistingPreference({
        userIsTrainingCoordinator,
        archivedOnly,
        isLoadingUserInfo,
    });
    const [notificationState, setNotificationState] = useState([]);

    const classTableConfig = useMemo(
        () => getConfiguration(intl, userIsTrainingCoordinator, archivedOnly),
        [intl, userIsTrainingCoordinator, archivedOnly]
    );

    const [state, dispatch] = useReducer(
        classListingTableStateProcessor,
        {
            classTableConfig,
            archivedOnly,
        },
        getClassListingTableInitialState
    );

    const eventDispatcher = useMemo(() => {
        return getClassListingTableEventDispatcher(dispatch, setNotificationState, intl);
    }, [dispatch, setNotificationState, intl]);
    const {
        classroomList,
        isLoading,
        isSuccess: isClassroomFetchingSuccessful,
        isTotalClassroomCountExact,
        totalClassroomCount,
        filterValueAggregation,
        classroomListRefetcher,
    } = useClassroomQuery({
        providerArn,
        pageNumber: state.currentPage,
        pageSize: tablePreferences.pageSize,
        sortByColumn: state.sorting.column,
        sortDescending: state.sorting.isDescending,
        filterOptions: state.filtering.activeFilters,
        filterFieldsRequestedForAggregation: state.filtering.filterAttributesToUpdate,
        isLoadingUserInfo,
    });

    React.useEffect(() => {
        if (acceptedInvitations && acceptedInvitations.length && isClassroomFetchingSuccessful) {
            new Promise(res => setTimeout(res, 3000)).then(classroomListRefetcher);
        }
    }, [acceptedInvitations, isClassroomFetchingSuccessful, classroomListRefetcher]);

    if (!isLoadingUserInfo) {
        return (
            <Grid
                gridDefinition={[{ colspan: 8, push: 0 }, { colspan: 12 }]}
                disableGutters={!notificationState || notificationState.length === 0}
            >
                <Flashbar items={notificationState} />
                <Table
                    columnDefinitions={classTableConfig.columnDefinitions}
                    items={classroomList}
                    loading={isLoading || isLoadingUserInfo}
                    loadingText="Loading resources"
                    sortingDisabled={isLoading}
                    sortingDescending={state.sorting.isDescending}
                    sortingColumn={state.sorting.column}
                    onSortingChange={event => eventDispatcher.sortingChange(event)}
                    resizableColumns={true}
                    visibleColumns={tablePreferences.visibleContent}
                    variant="container"
                    wrapLines={tablePreferences.wrapLines}
                    trackBy={item => item.classroomId}
                    stickyHeader
                    empty={
                        <Box textAlign="center" color="inherit">
                            <Box padding={{ bottom: 's' }} variant="p" color="inherit">
                                {intl.formatMessage(messages.noClasses)}
                            </Box>
                        </Box>
                    }
                    filter={
                        <ClassroomListFilter
                            filterConfig={classTableConfig.filterConfig}
                            classListingEventDispatcher={eventDispatcher}
                            filterOptionValues={filterValueAggregation}
                        />
                    }
                    header={
                        <Header
                            counter={
                                totalClassroomCount
                                    ? isTotalClassroomCountExact
                                        ? `(${totalClassroomCount})`
                                        : `(${totalClassroomCount}+)`
                                    : ''
                            }
                        >
                            {intl.formatMessage(
                                archivedOnly
                                    ? messages.classListArchivedHeading
                                    : messages.classListActiveUpcomingHeading
                            )}
                        </Header>
                    }
                    pagination={
                        <Pagination
                            pagesCount={Math.ceil(totalClassroomCount / tablePreferences.pageSize)}
                            currentPageIndex={state.currentPage}
                            disabled={isLoading}
                            onChange={event => eventDispatcher.paginationChange(event)}
                            openEnd={!isTotalClassroomCountExact}
                            ariaLabels={classTableConfig.paginationLabel}
                        />
                    }
                    preferences={
                        <ClassroomListPreferences
                            preferences={tablePreferences}
                            setPreferences={setTablePreferences}
                            pageSizeOptions={classTableConfig.pageSizeOptions}
                            visibleContentOptions={classTableConfig.visibleColumnOptions}
                            formatMessage={intl.formatMessage}
                        />
                    }
                />
            </Grid>
        );
    } else {
        //Don't render the class listing table until user detail is loaded since final table configuration
        //and query depends on user role.
        return <span />;
    }
};

export default ClassListingTable;
