import { getProviderReport } from 'modules';
import { downloadPresignUrl } from 'utils';
import messages from './ReportTable.messages';
import moment from 'moment';

const COLUMN_ID = {
    reportDate: 'reportDate',
    reportName: 'reportName',
    reportDescription: 'reportDescription',
};

export const buildColumnDefinitions = formatMessage => [
    {
        id: COLUMN_ID.reportDate,
        header: formatMessage(messages.reportDateColumn),
        cell: e => moment(e.reportDate).format('MM/DD/YY'),
    },
    {
        id: COLUMN_ID.reportName,
        header: formatMessage(messages.reportNameColumn),
        cell: e => e.reportName,
    },
    {
        id: COLUMN_ID.reportDescription,
        header: formatMessage(messages.reportDescriptionColumn),
        cell: e => e.reportDescription,
    },
];

export const paginationLabels = formatMessage => ({
    previousPageLabel: formatMessage(messages.previousPageLabel),
    nextPageLabel: formatMessage(messages.nextPageLabel),
    pageLabel: pageNumber => formatMessage(messages.pageLabel, { pageNumber }),
});

export const downloadReport = async (reportId, providerArn) => {
    const presignedUrl = await getProviderReport(reportId, providerArn);

    downloadPresignUrl(presignedUrl);
};

export const listReportsQueryOptions = {
    refetchOnWindowFocus: false,
};

export const getReportIdFromSelectedItems = selectedItems => selectedItems[0].reportId;

const PAGE_SIZE = 10;

export const paginatedReports = (reports, currentPage) => {
    if (!reports) {
        return [];
    }
    const pageStart = (currentPage - 1) * PAGE_SIZE;
    const pageEnd = pageStart + PAGE_SIZE;
    return reports.slice(pageStart, pageEnd);
};

export const getTotalPages = reports => {
    if (!reports) {
        return 1;
    }
    return Math.ceil(reports.length / PAGE_SIZE);
};
