import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    accessCodeHeader: {
        id: 'accessCodes.accessCodeHeader',
        defaultMessage: 'Student access codes',
    },
    studentRosterHeader: {
        id: 'accessCodes.studentRosterHeader',
        defaultMessage: 'Student access URL',
    },
    multiUseCodeLabel: {
        id: 'accessCodes.multiUseCodeLabel',
        defaultMessage: 'Multi-use access code',
    },
    singleAccessCodeLabel: {
        id: 'accessCodes.singleAccessCodeLabel',
        defaultMessage: 'Single-use access codes',
    },
    studentRosterLabel: {
        id: 'accessCodes.studentRosterLabel',
        defaultMessage: 'Student access URL',
    },
    accessCodeDescription: {
        id: 'accessCodes.accessCodeDescription',
        defaultMessage: 'Copy and share this URL with your students to grant access to your class',
    },
    studentAccessUrlLabel: {
        id: 'accesscodes.studentAccessUrlLabel',
        defaultMessage: 'Student access URL',
    },
    useCodeLabel: {
        id: 'accessCodes.useCodeLabel',
        defaultMessage: 'Codes used',
    },
    copyCodeLabel: {
        id: 'accessCodes.copyCodeLabel',
        defaultMessage: 'Copy student URL',
    },
    codeCopied: {
        id: 'accessCodes.codeCopied',
        defaultMessage: 'Copied!',
    },
    copyCodeButtonLabel: {
        id: 'accessCodes.copyCodeButtonLabel',
        defaultMessage: 'Copy access code to clipboard',
    },
    sortedAscending: {
        id: 'tableFiltering.sortedAscending',
        defaultMessage: 'sorted ascending',
    },
    sortedDescending: {
        id: 'tableFiltering.sortedDescending',
        defaultMessage: 'sorted descending',
    },
});

export default messages;
