import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    pageTitle: {
        id: 'ClassListPage.tcPageTitle',
        description: 'ClassList page title',
        defaultMessage: 'Classes',
    },
    createClass: {
        id: 'ClassListPage.createClass',
        description: 'link to create class page',
        defaultMessage: 'Create class',
    },
    activeLabel: {
        id: 'ClassListPage.activeLabel',
        description: 'Active classes text',
        defaultMessage: 'Active classes',
    },
    classesLabel: {
        id: 'ClassListPage.classesLabel',
        description: 'Active and upcoming classes text',
        defaultMessage: 'Classes',
    },
    archivedLabel: {
        id: 'ClassListPage.archivedLabel',
        description: 'Archived classes text',
        defaultMessage: 'Archived classes',
    },
    futureLabel: {
        id: 'ClassListPage.futureLabel',
        description: 'Future classes text',
        defaultMessage: 'Upcoming classes',
    },
    classListLabel: {
        id: 'ClassListPage.classListLabel',
        description: 'Title of class list page',
        defaultMessage: 'Class list',
    },
    reportDashLabel: {
        id: 'ClassListPage.reportDashLabel',
        description: 'Title of report dashboard page',
        defaultMessage: 'Report dashboard',
    },
    navigationTabLabel: {
        id: 'ClassListPage.navigationTabLabel',
        description: 'Accessibility label for navigation component',
        defaultMessage: 'Navigation tabs for class list page',
    },
});

export default messages;
