import { useEffect, useState } from 'react';

export const useStorage = (key, fallbackState, storage = window.sessionStorage) => {
    const parseSafely = input => {
        try {
            return JSON.parse(input);
        } catch (err) {
            // console.error(err);
            return null;
        }
    };
    const [value, valueSet] = useState(parseSafely(storage.getItem(key)) ?? fallbackState);

    useEffect(() => {
        storage.setItem(key, JSON.stringify(value));
    }, [key, value, storage]);

    return [value, valueSet];
};
