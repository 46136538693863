import React from 'react';
import { useIntl } from 'react-intl';
import { ColumnLayout, FormField, Input, FormSection } from '@amzn/awsui-components-react';

import { messages } from '../ClassForm.messages';
import {
    handleFormValueChange,
    MAX_CLASS_CAPACITY,
    MIN_CLASS_CAPACITY,
    getDisabledCapacityInputHint,
} from '../ClassForm.utils';
import InfoModal from '../../infoModal/InfoModal';
import { FieldErrors } from '../FieldErrors';
import '../ClassForm.css';
import { useGilmoreEvents } from 'data/useGilmoreEvents';

const StudentCode = ({ fieldsInvalid, classCapacity, data, hasClassExpired, setClassData }) => {
    const { formatMessage } = useIntl();
    const [openInfoModal, setOpenInfoModal] = React.useState(false);
    const { mostRecent, thereIsActiveEvent } = useGilmoreEvents();
    const disabledCapacityHint = !thereIsActiveEvent
        ? null
        : getDisabledCapacityInputHint(mostRecent, formatMessage);

    return (
        <FormSection
            id="class-access"
            header={formatMessage(messages.codeInfoHeader)}
            data-testid="class-capacity__form-section"
        >
            <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                    <p>{formatMessage(messages.multiUseCodeLabel)}</p>
                    <FormField
                        stretch
                        description={formatMessage(messages.numberOfStudentsHint, {
                            max: MAX_CLASS_CAPACITY,
                            min: MIN_CLASS_CAPACITY,
                        })}
                        hintText={disabledCapacityHint}
                        label={
                            <div>
                                <span>{formatMessage(messages.numberOfStudentsLabel)}</span>
                                &nbsp;-&nbsp;
                                <button
                                    className="info-link"
                                    onClick={() => setOpenInfoModal(true)}
                                    aria-label={formatMessage(messages.infoButtonOpenLabel)}
                                >
                                    {formatMessage(messages.infoButtonText)}
                                </button>
                            </div>
                        }
                    >
                        <Input
                            ariaRequired
                            invalid={!!fieldsInvalid.classCapacity}
                            disabled={!!(hasClassExpired || thereIsActiveEvent)}
                            value={classCapacity}
                            type="number"
                            data-testid="class-form__class-capacity"
                            onChange={e => {
                                handleFormValueChange({
                                    value: Math.min(
                                        MAX_CLASS_CAPACITY,
                                        Math.max(e.detail.value, MIN_CLASS_CAPACITY)
                                    ).toString(),
                                    setData: setClassData,
                                    keyPath: 'classCapacity',
                                });
                                const accessCode =
                                    parseInt(e.detail.value) !== data?.classCapacity
                                        ? data?.accessCodes[0]?.accessCode
                                        : '';
                                handleFormValueChange({
                                    value: accessCode,
                                    keyPath: 'accessCode',
                                    setData: setClassData,
                                });
                            }}
                        />
                        <FieldErrors fieldsInvalid={fieldsInvalid} property="classCapacity" />
                    </FormField>
                </div>
            </ColumnLayout>
            <InfoModal openInfoModal={openInfoModal} setOpenInfoModal={setOpenInfoModal} />
        </FormSection>
    );
};

export default StudentCode;
