import './LinkWidget.scss';
import { WidgetContainer, CopyButton } from 'components';
import { Link, Box } from '@amzn/awsui-components-react-v3';

/**
 * A widget which will show a link.
 * @param url - the url to show
 * @param copyable - if true, the widget will display a copy button for the url
 * @param external - if true, the widget will open the link in a new tab, and display a corresponding icon
 * @param title - the title for the widget
 */
const LinkWidget = props => {
    const { url, copyable, external, title } = props;
    return (
        <div className="flex-container">
            <WidgetContainer title={title}>
                <div className="jam-link">
                    <Link external={external} href={url}>
                        {url}
                    </Link>
                </div>
            </WidgetContainer>
            {copyable ? (
                <Box float="right">
                    <CopyButton>{url}</CopyButton>
                </Box>
            ) : null}
        </div>
    );
};

export default LinkWidget;
