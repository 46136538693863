import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react-v3';

import jamMessages from './JamCancelWarningModal.messages';

const { modalHeader, modalBody, maybeLaterBtn, cancelJamBtn } = jamMessages;

export const JamCancelWarningModal = ({ isVisible, onDismiss, jamAdminLink }) => {
    const { formatMessage } = useIntl();
    return (
        <Modal
            visible={isVisible}
            onDismiss={onDismiss}
            data-testid="cancel-class__jam-warning"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={onDismiss} variant="link">
                            {formatMessage(maybeLaterBtn)}
                        </Button>
                        <Button
                            iconAlign="right"
                            iconName="external"
                            target="_blank"
                            variant="primary"
                            href={jamAdminLink}
                        >
                            {formatMessage(cancelJamBtn)}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={formatMessage(modalHeader)}
        >
            {formatMessage(modalBody)}
        </Modal>
    );
};
