import { useEffect, useState } from 'react';
import { acceptInvitations } from 'graphql/mutations';
import { executeRequest } from 'modules/api';

const acceptAllInvitations = async (stateSetter, collection = []) => {
    const {
        acceptInvitations: { acceptedInvitations },
    } = await executeRequest({ operation: acceptInvitations });
    if (acceptedInvitations.length) {
        acceptAllInvitations(stateSetter, [...collection, ...acceptedInvitations]);
    }

    stateSetter(collection);
};

export const useAcceptInvitations = () => {
    const [acceptedInvitations, acceptedInvitationsSet] = useState(null);

    useEffect(() => {
        acceptAllInvitations(acceptedInvitationsSet);
    }, []);

    return acceptedInvitations;
};
