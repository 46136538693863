import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import { Button } from '@amzn/awsui-components-react';
import { Helmet } from 'react-helmet';

import { Loader } from 'components';
import { getProviders } from 'modules/api';
import { ProviderCtx } from 'data/ProviderContext';
import { paths } from 'utils/paths';
import { useAcceptInvitations } from 'utils/useAcceptInvitations';
import messages from './ProvidersPage.messages';

import './ProvidersPage.css';

export const ProviderType = Object.freeze({
    Direct: 'DIRECT',
    ATP: 'ATP',
});

// when data has a value, it means we expect a different response
// hence the amount of time to wait
const waitForConsistency = data =>
    new Promise(res => {
        setTimeout(res, data ? 2000 : 1);
    });

const ProvidersPage = () => {
    const { formatMessage } = useIntl();
    const { setProvider } = React.useContext(ProviderCtx);
    const { state } = useLocation();
    const history = useHistory();
    const [providerList, providerListSet] = React.useState();
    const [error, errorSet] = React.useState(false);
    const acceptedInvitations = useAcceptInvitations();

    const onProviderSelect = React.useCallback(
        data => {
            const path = paths.landingPage;
            setProvider(data);
            history.push(_get(state, 'referrer', path));
        },
        [history, setProvider, state]
    );

    React.useEffect(() => {
        if (acceptedInvitations && providerList) {
            if (acceptedInvitations.length === 0 && providerList.length) {
                // no need to get providers again if there were no new accepted invitations
                return;
            } else if (providerList.length === 0) {
                // stop showing the "no associated providers" message
                // because some are being fetched
                providerListSet(null);
            }
        }

        waitForConsistency(acceptedInvitations).then(() => {
            getProviders()
                .then(data => {
                    providerListSet(data);
                })
                .catch(err => {
                    if (acceptedInvitations) {
                        errorSet(true);
                    }
                });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acceptedInvitations]);

    React.useEffect(() => {
        if (providerList && providerList.length === 1) {
            onProviderSelect(providerList[0]);
        }
    }, [providerList, onProviderSelect]);

    return (
        <div className="providers-page">
            <Helmet>
                <title>{formatMessage(messages.pageTitle)}</title>
            </Helmet>
            <div className="providers-page__body awsui-util-container">
                <div className="awsui-util-container-header">
                    <h2>{formatMessage(messages.partnerSelectionTitle)}</h2>
                </div>
                <div className="providers-page__body__inner awsui-util-p-l awsui-util-pt-m">
                    <Loader
                        isLoading={!providerList}
                        message={formatMessage(messages.loadingPartners)}
                        fallback={() => formatMessage(messages.loadingFailure)}
                        backgroundColor="transparent"
                        hasError={error}
                    >
                        {providerList && providerList.length ? (
                            <ul>
                                {providerList.map(data => (
                                    <li key={data.arn}>
                                        <Button
                                            className="providers-page__button awsui-util-t-l"
                                            onClick={() => onProviderSelect(data)}
                                        >
                                            {data.name}
                                        </Button>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>{formatMessage(messages.noPartners)}</p>
                        )}
                    </Loader>
                </div>
            </div>
        </div>
    );
};

export default ProvidersPage;
