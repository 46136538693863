import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { cancelClassroom } from '../../modules';
import { paths } from '../../utils';
import messages from './CancelClass.messages';
import { ProviderType } from 'containers/providerPages/ProvidersPage';
import './CancelClass.scss';
import { useFlags } from '../../utils/flags';
import { JamCancelWarningModal } from '../jamCancelWarningModal/JamCancelWarningModal';

const CancelClass = ({
    classroomId,
    providerArn,
    isCancellable,
    trainingProviderType,
    jamAdminLink,
}) => {
    const { formatMessage } = useIntl();
    const [isVisible, setIsVisible] = useState(false);

    const onClick = () => setIsVisible(true);
    const onConfirm = async () => {
        await cancelClassroom(classroomId, providerArn);
    };

    const flags = useFlags();

    if (!(flags.cancelClassroom === true && isCancellable !== null)) return null;

    return (
        <>
            <Button
                variant="normal"
                className="class-detail__action-btn"
                disabled={isCancellable !== true}
                label={formatMessage(messages.cancelClassButtonLabel)}
                onClick={onClick}
                data-testid="cancel-class-button"
            >
                {formatMessage(messages.cancelClassButtonText)}
            </Button>
            <ConfirmationDialog
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                onConfirm={onConfirm}
                trainingProviderType={trainingProviderType}
                jamAdminLink={jamAdminLink}
            />
        </>
    );
};

const ConfirmationDialog = ({
    isVisible,
    setIsVisible,
    onConfirm,
    trainingProviderType,
    jamAdminLink,
}) => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [jamCancelModalIsVisible, jamCancelModalIsVisibleSet] = useState(false);

    const dismiss = () => {
        setErrorMessage('');
        setIsVisible(false);
    };

    const confirm = async () => {
        setIsLoading(true);
        try {
            await onConfirm();
            if (jamAdminLink) {
                jamCancelModalIsVisibleSet(true);
                setIsVisible(false);
            } else {
                history.push(paths.classListPage);
            }
        } catch (e) {
            setErrorMessage(formatMessage(messages.cancelClassFailedError));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Modal
                visible={isVisible}
                onDismiss={dismiss}
                closeLabel={formatMessage(messages.backButtonLabel)}
                header={formatMessage(messages.modalTitle)}
                footer={
                    <div className="cancel-class-modal__footer">
                        <Box color="text-status-error" data-testid="cancel-class-error">
                            {errorMessage}
                        </Box>
                        <SpaceBetween direction="horizontal" size="s">
                            <Button
                                onClick={confirm}
                                variant="primary"
                                loading={isLoading}
                                data-testid="cancel-class-confirm-button"
                            >
                                {formatMessage(messages.confirmButtonLabel)}
                            </Button>
                            <Button
                                onClick={dismiss}
                                variant="normal"
                                disabled={isLoading}
                                data-testid="cancel-class-back-button"
                            >
                                <span data-testid="cancel-class-modal__back-btn">
                                    {formatMessage(messages.backButtonLabel)}
                                </span>
                            </Button>
                        </SpaceBetween>
                    </div>
                }
                data-testid="cancel-class-modal"
            >
                {formatMessage(
                    trainingProviderType === ProviderType.Direct
                        ? messages.dialogMessage
                        : messages.dialogMessageForATP
                )}
            </Modal>
            <JamCancelWarningModal
                isVisible={jamCancelModalIsVisible}
                onDismiss={() => history.push(paths.classListPage)}
                jamAdminLink={jamAdminLink}
            />
        </>
    );
};

export default CancelClass;
