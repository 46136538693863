/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const clientLog = /* GraphQL */ `
  mutation ClientLog($input: Event!) {
    clientLog(input: $input) {
      statusCode
    }
  }
`;
export const updateTrainingPermissions = /* GraphQL */ `
  mutation UpdateTrainingPermissions($input: PermissionInput!) {
    updateTrainingPermissions(input: $input) {
      classroomId
      activeTrainings
      status
    }
  }
`;
export const createClassroom = /* GraphQL */ `
  mutation CreateClassroom($input: CreateClassroomInput!) {
    createClassroom(input: $input) {
      classroomId
    }
  }
`;
export const createClassroomV2 = /* GraphQL */ `
  mutation CreateClassroomV2($input: CreateClassroomV2Input!) {
    createClassroomV2(input: $input) {
      classroomId
    }
  }
`;
export const updateClassroom = /* GraphQL */ `
  mutation UpdateClassroom($input: UpdateClassroomInput!) {
    updateClassroom(input: $input) {
      classroomId
      langLocale
      courseId
      startsOn
      endsOn
      timezone
      locationType
      virtualUrl
      addressLine1
      addressLine2
      city
      state
      country
      postalCode
      classCapacity
    }
  }
`;
export const updateClassroomV2 = /* GraphQL */ `
  mutation UpdateClassroomV2($input: UpdateClassroomV2Input!) {
    updateClassroomV2(input: $input) {
      classroomId
      langLocale
      courseId
      startsOn
      endsOn
      timezone
      locationType
      virtualUrl
      addressLine1
      addressLine2
      city
      state
      country
      postalCode
      classCapacity
    }
  }
`;
export const cancelClassroom = /* GraphQL */ `
  mutation CancelClassroom($classroomId: String!, $providerArn: String) {
    cancelClassroom(classroomId: $classroomId, providerArn: $providerArn) {
      classroomArn
      providerArn
      langLocale
      courseId
      startsOn
      endsOn
      locationData {
        timezone
        displayName
        longitude
        latitude
        locationType
        virtualUrl
        physicalAddress {
          addressLine1
          addressLine2
          city
          state
          postalCode
          country
        }
      }
      courseTitle
      createdByEmail
      instructorEmails
    }
  }
`;
export const acceptInvitations = /* GraphQL */ `
  mutation AcceptInvitations {
    acceptInvitations {
      acceptedInvitations {
        email
        invitationArn
        relationship
        resourceArn
        status
      }
    }
  }
`;
export const createClassroomRelationships = /* GraphQL */ `
  mutation CreateClassroomRelationships(
    $input: CreateClassroomRelationshipsInput!
  ) {
    createClassroomRelationships(input: $input) {
      statusCode
    }
  }
`;
export const addStudentsToClassroomRoster = /* GraphQL */ `
  mutation AddStudentsToClassroomRoster(
    $input: AddStudentsToClassroomRosterInput!
  ) {
    addStudentsToClassroomRoster(input: $input) {
      statusCode
    }
  }
`;
export const createStudentTrainings = /* GraphQL */ `
  mutation CreateStudentTrainings($input: CreateStudentTrainingsInput!) {
    createStudentTrainings(input: $input) {
      batchId
      created
    }
  }
`;
export const excludeUser = /* GraphQL */ `
  mutation ExcludeUser($input: ExcludeUserInput!) {
    excludeUser(input: $input) {
      email
      invitationArn
      relationship
      resourceArn
      status
    }
  }
`;
export const cancelStudentTrainings = /* GraphQL */ `
  mutation CancelStudentTrainings($input: CancelStudentTrainingsInput!) {
    cancelStudentTrainings(input: $input) {
      batchId
      created
    }
  }
`;
