import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
    headerLabel: {
        id: 'studentRosterModal.headerLabel',
        defaultMessage: 'Student list',
    },
    bodyTitle: {
        id: 'studentRosterModal.bodyTitle',
        defaultMessage: "Add students' email",
    },
    bodyInstructions: {
        id: 'studentRosterModal.bodyInstructions',
        defaultMessage: 'Separate emails with a comma',
    },
    closeModalButtonText: {
        id: 'studentRosterModal.closeModalButtonText',
        defaultMessage: 'Cancel',
    },
    addListModalButtonText: {
        id: 'studentRosterModal.addListModalButtonText',
        defaultMessage: 'Add',
    },
    invalidEmail: {
        id: 'studentRosterModal.invalidEmail',
        defaultMessage: 'is not a valid email.',
    },
    closeModalButtonLabel: {
        id: 'studentRosterModal.closeModalButtonLabel',
        defaultMessage: 'Close Modal',
    },
    classCapacityBody: {
        id: 'studentRosterModal.classCapacityBody',
        defaultMessage: 'You can add up to {classCapacity} student(s) to this class.',
    },
    placeholder: {
        id: 'studentRosterModal.placeholder',
        defaultMessage: 'example1@domain.com,example2@domain.com',
    },
    classCapacityExceededMessage: {
        id: 'studentRosterModal.classCapacityExceededMessage',
        defaultMessage:
            'The student list exceeds the class license size by {size}. If you want to continue, your class capacity will be updated to accomodate the new student list size.',
    },
    classCapacityExceededMessageWithSubProvider: {
        id: 'studentRosterModal.classCapacityExceededMessageWithSubProvider',
        defaultMessage:
            'The student list exceeds the class license size by {size}. If you want to continue, edit the class details to increase the overall size of the class assigned to the correct Gilmore ID provider.',
    },
    updateClassCapacity: {
        id: 'studentRosterModal.updateClassCapacity',
        defaultMessage: 'Update class capacity',
    },
    goToEditClass: {
        id: 'studentRosterModal.goToEditClassButton',
        defaultMessage: 'Go to Edit Class',
    },
    classLimitBody: {
        id: 'studentRosterModal.classLimitBody',
        defaultMessage: "You've reached the student limit for this class.",
    },
    gilmoreLicenseError: {
        id: 'studentRosterModal.gilmoreLicenseError',
        defaultMessage:
            "You've reached the limit for this class size and you don't have enough student eKits to increase it. Edit your student list or visit the Gilmore storefront to buy more student eKits.",
    },
    gilmoreLicenseStoreFrontLink: {
        id: 'studentRoster.gilmoreLicenseStoreFrontLink',
        defaultMessage: 'Visit Gilmore storefront',
    },
    editStudentListButton: {
        id: 'studentRoster.editStudentListButton',
        defaultMessage: 'Edit student list',
    },
    classCapacityPlannedOutageDisabled: {
        id: 'studentRoster.classCapacity.plannedOutageDisabled',
        defaultMessage:
            'You may increase the number of students in this class after {time} on {date}',
    },
    classCapacityUnplannedOutageDisabled: {
        id: 'studentRoster.classCapacity.unplannedOutageDisabled',
        defaultMessage: 'Changing class capacity is temporarily unavailable. Check back later.',
    },
    classCapacityNoUnassignedSeats: {
        id: 'studentRoster.classCapacity.noUnassignedSeats',
        defaultMessage: 'There are no unassigned seats in this class.',
    },
    classCapacitySingleUnassignedSeats: {
        id: 'studentRoster.classCapacity.singleUnassignedSeats',
        defaultMessage: 'You may add up to one student right now.',
    },
    classCapacityMultipleUnassignedSeats: {
        id: 'studentRoster.classCapacity.multipleUnassignedSeats',
        defaultMessage: 'You may add up to {num} students right now.',
    },
});
