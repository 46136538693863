import moment from 'moment-timezone';

export const dateTimeToUnix = (date, time, timezone) => {
    const formattedDateTime = `${date} ${time}`.replace(/\//g, '-');
    return parseInt(moment.tz(formattedDateTime, timezone).format('X'));
};

/**
 *
 * @param {*} param0
 * @param {string} timezone
 */
export const getEpochDate = ({ startDate, startTime, endDate, endTime }, timezone) => {
    const start = dateTimeToUnix(startDate, startTime, timezone);
    const end = dateTimeToUnix(endDate, endTime, timezone);

    return { startsOn: start, endsOn: end };
};

export const DATEPICKER_FORMAT = 'YYYY-MM-DD';

/**
 * @param {String} startsOn Start date in epoch format
 * @param {String} endsOn End date in epoch format
 * @param {String} timezone Timezone for dates
 * @return {Object} Object containing dates and times for input values
 *
 * @note Start and end data is currently in epoch seconds
 */
export const getDateTimeData = ({ startsOn, endsOn, timezone }) => {
    if (!startsOn || !endsOn) return defaultDateTimeData;

    const start = moment.unix(startsOn).tz(timezone);
    const end = moment.unix(endsOn).tz(timezone);

    return {
        startDate: start.format(DATEPICKER_FORMAT),
        endDate: end.format(DATEPICKER_FORMAT),
        startTime: formatTime(start),
        endTime: formatTime(end),
    };
};

export const formatTime = dateTime => dateTime.format('HH:mm');

export const isInvalidDate = (start, end) => {
    if (!start || !end) return false;
    const startDate = moment(start, DATEPICKER_FORMAT);
    const endDate = moment(end, DATEPICKER_FORMAT);
    return startDate.isAfter(endDate);
};

export const isInvalidDateDiff = (start, end) => {
    if (!start || !end) return false;
    const startDate = moment(start, DATEPICKER_FORMAT);
    const endDate = moment(end, DATEPICKER_FORMAT);
    return endDate.diff(startDate, 'weeks') >= 5;
};

export const isInvalidTime = ({ startTime, endTime, startDate, endDate }) => {
    if (startDate !== endDate) return false;
    if (startTime && endTime && startTime >= endTime) return true;
    return false;
};

export const isDateTimeInPast = (date, time, timezone) => {
    if (!date || !time || !timezone) return false;
    const dateTime = dateTimeToUnix(date, time, timezone);

    return +moment.tz(moment.now(), timezone).format('X') > dateTime;
};

export const timeHasPassed = unixTime => moment.unix(unixTime).isBefore(moment(moment.now()));

export const formatDateString = mom => mom.format(DATEPICKER_FORMAT);

export const sameStartAndEnd = ({ startDate, endDate }) => startDate === endDate;

export const defaultDateTimeData = {
    startDate: formatDateString(moment().add(1, 'days')),
    endDate: formatDateString(moment().add(30, 'days')),
    startTime: '09:30',
    endTime: '17:00',
};

export const unixToDateString = (unixTime, timezone, addWeeks = 0) =>
    moment
        .unix(unixTime)
        .tz(timezone)
        .add(addWeeks, 'weeks')
        .format(DATEPICKER_FORMAT);

export const isEndDateEnabled = (currentEndDate, newEndDate, hasClassStarted) => {
    return (
        !isInvalidDateDiff(currentEndDate, newEndDate) &&
        (!hasClassStarted || !isInvalidDate(currentEndDate, newEndDate))
    );
};

export const nowInUnixTimeInSec = () => moment().unix();

/**
 * Custom method that returns time in a formatted string with
 * short time zone name, ex: 9:00 AM- 10:00 AM
 * @param {Number} value Date value to be formatted, expected in unix format
 * @param {String} timezone Timezone for date value
 * @return {String} Formatted value of time based on locale settings
 */
export const toDetailPageTimeFormat = (value, timezone) =>
    moment
        .unix(value)
        .tz(timezone)
        .format('hh:mm A');

export const durationInHours = ({ startsOn, endsOn, timezone }) =>
    Math.round(
        moment
            .unix(endsOn)
            .tz(timezone)
            .diff(moment.unix(startsOn).tz(timezone), 'hours')
    );
