import React from 'react';

export const FlagContext = React.createContext();

const toBooleanValue = val => /^true$/i.test(val);
export const FlagContextProvider = ({ children }) => (
    <FlagContext.Provider
        value={{
            studentRoster: toBooleanValue(process.env.studentRoster),
            cancelClassroom: toBooleanValue(process.env.cancelClassroom), // https://sim.amazon.com/issues/BKR-4805
            // TODO: (https://sim.amazon.com/issues/BKR-4926) remove class listing phase 2 flag after release
            classListingV2: toBooleanValue(process.env.classListingV2),
            enableClassListingWithMock: toBooleanValue(process.env.enableClassListingWithMock),
            scheduledEvents: toBooleanValue(process.env.scheduledEvents),
            multiGilmoreId: toBooleanValue(process.env.multiGilmoreId),
            endLabs: toBooleanValue(process.env.endLabs), // https://sim.amazon.com/issues/BKR-5986
        }}
    >
        {children}
    </FlagContext.Provider>
);

export const useFlags = () => React.useContext(FlagContext);
