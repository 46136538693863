import {
    Box,
    Container,
    DatePicker,
    FormField,
    Grid,
    Input,
    RadioGroup,
    TimeInput,
} from '@amzn/awsui-components-react-v3';
import {
    handleFormValueChange,
    MIN_JAM_TEAM_SIZE,
    MAX_JAM_TEAM_SIZE,
    JAM_DURATION_DEFAULT,
} from 'components/classForm/ClassForm.utils';
import { FieldErrors } from 'components/classForm/FieldErrors';
import { useIntl } from 'react-intl';
import { messages } from './JamFormSection.messages';
import './JamFormSection.scss';
import { JamHeader } from '../jamHeader/JamHeader';
import moment from 'moment-timezone';

const {
    startDateInputLabel,
    startDateInputDescription,
    startDateInputWarning,
    startTimeInputLabel,
    startTimeInputDescription,
    durationInputLabel,
    durationInputDescription,
    durationInputRadioLabel,
    teamSizeInputLabel,
    teamSizeInputDescription,
} = messages;

const JamFormSection = ({
    jamInputDataSet,
    jamInputData,
    enabled,
    fieldsInvalid,
    jamTraining = {},
}) => {
    const { formatMessage } = useIntl();
    const inputSet = keyPath => ({ detail }) =>
        handleFormValueChange({ value: detail?.value, setData: jamInputDataSet, keyPath });
    if (!enabled) return null;
    const gridDefinition = [{ colspan: { default: 5 } }, { colspan: { default: 4 } }];
    const jamEventHasStarted = jamTraining?.metaData?.startsOn < moment().unix();
    const jamEventHasEnded = jamTraining?.metaData?.endsOn < moment().unix();
    return (
        <Box margin={{ bottom: 'l' }} data-testid="class-form-jam-section">
            <Container header={<JamHeader />}>
                <Box margin={{ top: 'xxs', bottom: 'l' }}>
                    <Grid gridDefinition={gridDefinition}>
                        <FormField
                            label={formatMessage(startDateInputLabel)}
                            constraintText={formatMessage(startDateInputWarning)}
                            description={formatMessage(startDateInputDescription)}
                        >
                            <DatePicker
                                placeholder="YYYY/MM/DD"
                                value={jamInputData.startDate}
                                invalid={!!fieldsInvalid?.jamStartDate}
                                disabled={jamEventHasStarted}
                                onChange={inputSet('startDate')}
                            />
                            <FieldErrors fieldsInvalid={fieldsInvalid} property="jamStartDate" />
                        </FormField>
                        <FormField
                            label={formatMessage(startTimeInputLabel)}
                            description={formatMessage(startTimeInputDescription)}
                        >
                            <TimeInput
                                format="hh:mm"
                                placeholder="hh:mm"
                                disabled={jamEventHasStarted}
                                value={jamInputData.startTime}
                                onChange={inputSet('startTime')}
                            />
                        </FormField>
                    </Grid>
                </Box>
                <Box margin={{ bottom: 'l' }}>
                    <FormField
                        label={formatMessage(durationInputLabel)}
                        description={formatMessage(durationInputDescription)}
                    >
                        <RadioGroup
                            onChange={inputSet('duration')}
                            value={jamInputData.duration}
                            disabled={jamEventHasEnded}
                            invalid={!!fieldsInvalid?.jamDuration}
                            items={[
                                {
                                    value: JAM_DURATION_DEFAULT,
                                    label: formatMessage(durationInputRadioLabel, {
                                        number: JAM_DURATION_DEFAULT,
                                    }),
                                    disabled: jamEventHasEnded,
                                },
                                {
                                    value: JAM_DURATION_DEFAULT * 2,
                                    label: formatMessage(durationInputRadioLabel, {
                                        number: JAM_DURATION_DEFAULT * 2,
                                    }),
                                    disabled: jamEventHasEnded,
                                },
                            ]}
                        />
                        <FieldErrors fieldsInvalid={fieldsInvalid} property="jamDuration" />
                    </FormField>
                </Box>
                <FormField
                    label={formatMessage(teamSizeInputLabel)}
                    description={formatMessage(teamSizeInputDescription, {
                        lower: MIN_JAM_TEAM_SIZE,
                        upper: MAX_JAM_TEAM_SIZE,
                    })}
                >
                    <Input
                        onChange={inputSet('teamSize')}
                        disabled={jamEventHasEnded}
                        value={jamInputData.teamSize}
                        invalid={!!fieldsInvalid?.jamTeamSize}
                        controlId="class-form-jam__team-size"
                    />
                    <FieldErrors fieldsInvalid={fieldsInvalid} property="jamTeamSize" />
                </FormField>
            </Container>
        </Box>
    );
};

export default JamFormSection;
