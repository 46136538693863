import { getAccessToken } from 'modules';

const decodeJWT = async () => {
    const cognitoIdToken = await getAccessToken();
    if (cognitoIdToken) {
        return JSON.parse(atob(cognitoIdToken.split('.')[1]));
    } else {
        return 'user has no hat_id';
    }
};

export const createLogMessage = async ({ payload }) => {
    const { hat_id } = await decodeJWT();
    return {
        userId: hat_id,
        ...payload,
    };
};
