import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
  cancelClassButtonText: {
    id: 'cancelClass.buttonText',
    defaultMessage: 'Cancel class',
  },
  cancelClassButtonLabel: {
    id: 'cancelClass.buttonLabel',
    defaultMessage: 'Cancel class',
  },
  modalTitle: {
    id: 'cancelClass.modalTitle',
    defaultMessage: 'Cancel class',
  },
  confirmButtonLabel: {
    id: 'cancelClass.confirmButtonLabel',
    defaultMessage: 'Cancel class',
  },
  backButtonLabel: {
    id: 'cancelClass.backButtonLabel',
    defaultMessage: 'Back',
  },
  dialogMessage: {
    id: 'cancelClass.dialogMessage',
    defaultMessage: 'Are you sure you want to cancel this class? Once a class is cancelled, you won\'t be able to access this class.',
  },
  dialogMessageForATP: {
    id: 'cancelClass.dialogMessageForATP',
    defaultMessage: 'Are you sure you want to cancel this class? Once a class is cancelled, you won\'t be able to access this class. The licenses allocated for this class will be released within 24 hours.',
  },
  cancelClassFailedError: {
    id: 'cancelClass.cancelClassFailedError',
    defaultMessage: 'Failed to cancel the class. Please try again later.'
  }
});

export default messages;
