import { Alert } from '@amzn/awsui-components-react-v3';
import { useIntl } from 'react-intl';
import moment from 'moment-timezone';

import { useGilmoreEvents } from 'data/useGilmoreEvents';
import messages from './GilmoreHealthBanner.messages';

const PLANNED_TYPE = 'PLANNED';
const GILMORE_DOMAIN = 'GILMORE';

const shouldDisplayBanner = dataEvents => {
    if (!dataEvents?.length) {
        return false;
    }
    return dataEvents.some(ev => ev.service === GILMORE_DOMAIN && ev.endTime >= moment().unix());
};

const getUpcomingEventHeader = ev => {
    const now = moment();
    const daySpan = moment.unix(ev.startTime).dayOfYear() - now.dayOfYear();
    switch (daySpan) {
        case 0:
            return messages.upcomingTodayPlannedHeader;
        case -364:
            return now.isLeapYear()
                ? messages.upcomingPlannedHeader
                : messages.upcomingTomorrowPlannedHeader;
        case -365:
        case 1:
            return messages.upcomingTomorrowPlannedHeader;
        default:
            return messages.upcomingPlannedHeader;
    }
};

const getUpcomingEventBody = ev => {
    const startTime = moment.unix(ev.startTime);
    const endTime = moment.tz(moment.unix(ev.endTime), moment.tz.guess(true));
    const daySpan = endTime.date() - startTime.date();
    if (daySpan === 0) {
        return {
            bodyMessage: messages.upcomingSingleDayPlannedText,
            args: {
                date: startTime.format('MMMM D, YYYY'),
                startTime: startTime.format('hh:mm A'),
                endTime: endTime.format('LT z'),
            },
        };
    }
    return {
        bodyMessage: messages.upcomingPlannedText,
        args: {
            startTime: startTime.format('hh:mm A MMMM D'),
            endTime: endTime.format('LT MMMM D z'),
        },
    };
};

const computeMessaging = (mostRecent, thereIsActiveEvent, formatMessageFn) => {
    if (thereIsActiveEvent) {
        if (mostRecent.type !== PLANNED_TYPE) {
            return {
                header: null,
                text: formatMessageFn(messages.unplannedText),
            };
        }
        const endTimeInTimeZone = moment.tz(moment.unix(mostRecent.endTime), moment.tz.guess(true));
        const formatArguments = {
            date: endTimeInTimeZone.format('MMMM D, YYYY'),
            time: endTimeInTimeZone.format('LT z'),
        };
        return {
            header: formatMessageFn(messages.ongoingPlannedHeader),
            text: formatMessageFn(messages.ongoingPlannedText, formatArguments),
        };
    }
    const headerMessage = getUpcomingEventHeader(mostRecent);
    const { bodyMessage, args } = getUpcomingEventBody(mostRecent);
    return {
        header: formatMessageFn(headerMessage),
        text: formatMessageFn(bodyMessage, args),
    };
};

const GilmoreHealthBanner = () => {
    const { formatMessage } = useIntl();
    const { gilmoreEventData, thereIsActiveEvent, mostRecent } = useGilmoreEvents();
    if (!shouldDisplayBanner(gilmoreEventData)) {
        return null;
    }
    const { header, text } = computeMessaging(mostRecent, thereIsActiveEvent, formatMessage);

    return (
        <Alert
            data-testid="scheduled-events-banner"
            header={header}
            type={thereIsActiveEvent ? 'warning' : 'info'}
            visible
        >
            {text}
        </Alert>
    );
};

export default GilmoreHealthBanner;
