import moment from 'moment-timezone';
import { dateTimeToUnix } from 'utils/timestamp-utils';

export const MIN_JAM_TEAM_SIZE = 3;
export const MAX_JAM_TEAM_SIZE = 6;
export const JAM_DURATION_DEFAULT = 4;
export const JAM_START_BUFFER_MINUTES = 10;

export const getJamTraining = trainings =>
    trainings?.length &&
    trainings.find(training => training?.trainingType === 'JAM' && !!training.fulfillmentStatus);

export const jamEventValidator = (requiredFieldValidator, errorCodeMap) => (
    data,
    originalJamTrainings
) => {
    const { jamData, startDate, startTime, endDate, endTime, timezone } = data;
    const nowPlusBufferMinutes = moment()
        .add(JAM_START_BUFFER_MINUTES, 'minute')
        .unix();
    const jamTrainingObject = getJamTraining(originalJamTrainings);
    const errors = {
        jamStartDate: requiredFieldValidator(jamData?.startDate),
        jamStartTime: requiredFieldValidator(jamData?.startTime),
        jamTeamSize: requiredFieldValidator(jamData?.teamSize),
        jamDuration: requiredFieldValidator(jamData?.duration),
    };
    if (!errors.jamTeamSize) {
        const parsedTeamSize = parseInt(jamData.teamSize, 10);
        if (parsedTeamSize > MAX_JAM_TEAM_SIZE || parsedTeamSize < MIN_JAM_TEAM_SIZE) {
            errors.jamTeamSize = [{ code: errorCodeMap.invalid, values: [jamData.teamSize] }];
        }
    }
    if (!errors.jamStartDate && !errors.jamStartTime) {
        const jamStartsOn = dateTimeToUnix(jamData.startDate, jamData.startTime, timezone);
        const startsOn = dateTimeToUnix(startDate, startTime, timezone);
        const diffBetweenTimes = Math.abs(
            jamStartsOn - (jamTrainingObject?.metaData?.startsOn || 0)
        );
        const startTimeHasChanged = diffBetweenTimes > 1;
        if (jamStartsOn < startsOn) {
            errors.jamStartDate = [
                {
                    code: errorCodeMap.invalid,
                    values: [jamData.startDate, jamData.startTime],
                },
            ];
        } else if (startTimeHasChanged && jamStartsOn < nowPlusBufferMinutes) {
            errors.jamStartDate = [
                {
                    code: errorCodeMap.invalid,
                    values: [jamData.startDate, jamData.startTime],
                },
            ];
        }
        if (!errors.jamDuration) {
            const jamEndsOn = jamStartsOn + parseInt(jamData.duration, 10) * 3600;
            const endsOn = dateTimeToUnix(endDate, endTime, timezone);
            if (jamEndsOn > endsOn) {
                errors.jamDuration = [{ code: errorCodeMap.invalid, values: [jamData.duration] }];
            }
        }
    }
    return errors;
};
