import { useEffect, useMemo } from 'react';
import { Alert, Modal, Textarea, Box, Button, SpaceBetween } from '@amzn/awsui-components-react-v3';
import throttle from 'lodash/throttle';
import { ErrorMessage } from 'components/classForm/FieldErrors';
import { useIntl } from 'react-intl';
import { messages } from './StudentRosterModal.messages';
import { useGilmoreEvents } from 'data/useGilmoreEvents';
import {
    createStudentListEditState,
    getDisabledCapacityInputHint,
} from 'components/classForm/ClassForm.utils';
import { useProvider } from '../../data/ProviderContext';
import { paths } from '../../utils';
import { useHistory } from 'react-router-dom';

const THROTTLE_MS = 500;

const StudentRosterModal = ({
    openStudentListModal,
    closeStudentListModal,
    setStudentListValue,
    studentListValue,
    addStudentsToRoster,
    studentRosterErrors,
    classCapacitySizeExceededBy,
    setClassCapacitySizeExceededBy,
    classCapacity,
    roster,
    capacityUpdateWithRoster,
    gilmoreLicenseError,
    setGilmoreLicenseError,
    showBtnSpinner,
    studentCount,
    classroomId,
}) => {
    const { formatMessage } = useIntl();
    const { thereIsActiveEvent, mostRecent: mostRecentEvent } = useGilmoreEvents();
    const provider = useProvider();
    const classCapacityExceeded = classCapacitySizeExceededBy > 0;
    const requiresSubProvider = provider?.requiresSubProvider;

    const resetOverCapacityAndLicenseError = () => {
        setClassCapacitySizeExceededBy(0);
        setGilmoreLicenseError(false);
    };

    const licensesReserved = studentCount > roster.length ? studentCount : roster.length;
    const availableCapacity = classCapacity - licensesReserved;
    const hasCapacity = availableCapacity > 0;
    const DisabledCapacityAlertMessage = () => (
        <>
            {availableCapacity === 0 && formatMessage(messages.classCapacityNoUnassignedSeats)}
            {availableCapacity === 1 && formatMessage(messages.classCapacitySingleUnassignedSeats)}
            {availableCapacity > 1 &&
                formatMessage(messages.classCapacityMultipleUnassignedSeats, {
                    num: availableCapacity,
                })}{' '}
            {getDisabledCapacityInputHint(mostRecentEvent, formatMessage, {
                plannedMessage: messages.classCapacityPlannedOutageDisabled,
                unplannedMessage: messages.classCapacityUnplannedOutageDisabled,
            })}
        </>
    );
    const addStudentsToRosterHandler = useMemo(() => throttle(addStudentsToRoster, THROTTLE_MS), [
        addStudentsToRoster,
    ]);

    useEffect(
        () => () => {
            addStudentsToRosterHandler.cancel();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    return (
        <Modal
            onDismiss={closeStudentListModal}
            closeAriaLabel={formatMessage(messages.closeModalButtonLabel)}
            data-testid="student-roster-modal"
            visible={openStudentListModal}
            header={formatMessage(messages.headerLabel)}
            footer={
                <Footer
                    requiresSubProvider={requiresSubProvider}
                    onCloseStudentListModal={closeStudentListModal}
                    addStudentsToRosterHandler={addStudentsToRosterHandler}
                    classCapacitySizeExceededBy={classCapacitySizeExceededBy}
                    capacityUpdateWithRoster={capacityUpdateWithRoster}
                    gilmoreLicenseError={gilmoreLicenseError}
                    thereIsActiveEvent={thereIsActiveEvent}
                    showBtnSpinner={showBtnSpinner}
                    resetOverCapacityAndLicenseError={resetOverCapacityAndLicenseError}
                    formatMessage={formatMessage}
                    classroomId={classroomId}
                    studentListValue={studentListValue}
                />
            }
        >
            <Alert visible={thereIsActiveEvent && !classCapacityExceeded} type="warning">
                <DisabledCapacityAlertMessage />
            </Alert>

            <Alert visible={classCapacityExceeded}>
                {formatMessage(
                    requiresSubProvider
                        ? messages.classCapacityExceededMessageWithSubProvider
                        : messages.classCapacityExceededMessage,
                    {
                        size: classCapacitySizeExceededBy,
                    }
                )}
            </Alert>

            <Alert visible={gilmoreLicenseError} type="error">
                {formatMessage(messages.gilmoreLicenseError)}
            </Alert>

            <p style={{ marginBottom: 0 }}>{formatMessage(messages.bodyTitle)}</p>
            <p style={{ marginTop: 0, color: 'gray' }}>
                {formatMessage(messages.bodyInstructions)}
            </p>
            <Textarea
                onChange={({ detail }) => setStudentListValue(detail.value)}
                value={studentListValue}
                ariaLabel="student-emails"
                placeholder={formatMessage(messages.placeholder)}
                disabled={classCapacityExceeded || gilmoreLicenseError}
            ></Textarea>
            <p style={{ color: 'gray' }}>
                {!hasCapacity
                    ? formatMessage(messages.classLimitBody)
                    : formatMessage(messages.classCapacityBody, {
                          classCapacity: availableCapacity,
                      })}
            </p>
            {studentRosterErrors.length > 0 &&
                studentRosterErrors.map((error, index) => (
                    <ErrorMessage data-testid="student-roster-error" key={index}>
                        {error.email} {formatMessage(messages.invalidEmail)}
                    </ErrorMessage>
                ))}
        </Modal>
    );
};

const Footer = ({
    requiresSubProvider,
    onCloseStudentListModal,
    addStudentsToRosterHandler,
    classCapacitySizeExceededBy,
    capacityUpdateWithRoster,
    gilmoreLicenseError,
    thereIsActiveEvent,
    showBtnSpinner,
    resetOverCapacityAndLicenseError,
    formatMessage,
    classroomId,
    studentListValue,
}) => {
    const history = useHistory();
    let onClassCapacityExceedButton = requiresSubProvider ? (
        <Button
            onClick={() =>
                history.push(
                    `${paths.classEditPage(classroomId)}`,
                    createStudentListEditState(classroomId, studentListValue, true)
                )
            }
            loading={showBtnSpinner}
            disabled={thereIsActiveEvent}
            variant="primary"
        >
            {formatMessage(messages.goToEditClass)}
        </Button>
    ) : (
        <Button
            onClick={capacityUpdateWithRoster}
            loading={showBtnSpinner}
            disabled={thereIsActiveEvent}
            variant="primary"
        >
            {formatMessage(messages.updateClassCapacity)}
        </Button>
    );

    return (
        <div>
            <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                    {gilmoreLicenseError ? (
                        <>
                            <Button
                                href="https://aws.gilmoreglobal.com/login"
                                iconAlign="left"
                                iconName="external"
                                target="blank"
                                variant="link"
                            >
                                {formatMessage(messages.gilmoreLicenseStoreFrontLink)}
                            </Button>
                            <Button onClick={resetOverCapacityAndLicenseError} variant="primary">
                                {formatMessage(messages.editStudentListButton)}
                            </Button>
                        </>
                    ) : (
                        <>
                            {classCapacitySizeExceededBy > 0 ? (
                                <>
                                    <Button
                                        onClick={resetOverCapacityAndLicenseError}
                                        variant="link"
                                    >
                                        {formatMessage(messages.editStudentListButton)}
                                    </Button>
                                    {onClassCapacityExceedButton}
                                </>
                            ) : (
                                <>
                                    <Button onClick={onCloseStudentListModal} variant="normal">
                                        {formatMessage(messages.closeModalButtonText)}
                                    </Button>
                                    <Button
                                        onClick={addStudentsToRosterHandler}
                                        loading={showBtnSpinner}
                                        variant="primary"
                                    >
                                        {formatMessage(messages.addListModalButtonText)}
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                </SpaceBetween>
            </Box>
        </div>
    );
};

export default StudentRosterModal;
