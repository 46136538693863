import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@amzn/awsui-components-react';
import { Tabs } from '@amzn/awsui-components-react-v3';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

import {
    PageWrapper,
    MyClassesTable,
    ProviderClassesTable,
    SectionHeader,
    ReportTable,
    GilmoreHealthBanner,
} from 'components';
import { paths } from 'utils/paths';
import { useProvider } from 'data/ProviderContext';
import messages from './ClassListPage.messages';
import { useUserInfo } from 'utils/userInfo';
import './ClassListPage.css';

const ClassListPage = () => {
    const { userIsTrainingCoordinator } = useUserInfo();
    const { formatMessage } = useIntl();
    const history = useHistory();
    const providerName = useProvider()?.name;

    const navigationTabs = [
        {
            id: 'class-list',
            label: formatMessage(messages.classListLabel),
        },
    ];
    if (userIsTrainingCoordinator) {
        navigationTabs.push({
            id: 'report-dash',
            label: formatMessage(messages.reportDashLabel),
        });
    }

    const initialNavTab = navigationTabs[0].id;
    const [activeNavTab, setActiveNavTab] = useState(initialNavTab);

    const ClassesComponent = userIsTrainingCoordinator ? ProviderClassesTable : MyClassesTable;

    return (
        <PageWrapper>
            <Helmet>
                <title>{formatMessage(messages.pageTitle)}</title>
            </Helmet>
            <GilmoreHealthBanner />
            <SectionHeader
                title={providerName}
                variant="h1"
                actions={
                    <Button onClick={() => history.push(paths.classPage('new'))}>
                        {formatMessage(messages.createClass)}
                    </Button>
                }
            />
            <Tabs
                className="navigation-tabs"
                disableContentPaddings
                activeTabId={activeNavTab}
                ariaLabel={formatMessage(messages.navigationTabLabel)}
                onChange={event => setActiveNavTab(event.detail.activeTabId)}
                tabs={navigationTabs}
                id="class-list-nav-tabs"
            />
            {activeNavTab === 'class-list' && <ClassesComponent />}
            {activeNavTab === 'report-dash' && <ReportTable />}
        </PageWrapper>
    );
};

export default ClassListPage;
