import { COLUMN_IDS } from './classlistTableConfig';
import { listProviderClassroomsV2, listInstructorClassroomsV2 } from '../../modules';
import moment from 'moment-timezone';

/**
 * Integrating layer for converting between view models from ClassroomListingTableV2 component
 * and API models for calling Gonzo's listProviderClassroomV2 and listInstructorClassroomV2.
 *
 * API (GraphQL) models are defined in schema.json.
 */

/**
 * Query classroom by provider.
 * User must be a coordinator for the requested provider.
 * Supports filter, sorting and pagination.
 * Query can request field to be aggregated.  Aggregating a field means grouping the different values
 * for the given field and counting the occurrence of each value. Aggregations are used as auto suggestions
 * for filters (i.e. courseTitle aggregation is used to show as autosuggestion the possible values for course title)
 */
export const queryClassroomListByProvider = async ({
    providerArn,
    pageNumber,
    pageSize,
    sortByColumn,
    sortDescending,
    filterOptions,
    fieldsRequestedForAggregation,
}) => {
    const apiParam = getApiParam({
        providerArn,
        pageNumber,
        pageSize,
        sortByColumn,
        sortDescending,
        filterOptions,
        fieldsRequestedForAggregation,
        isCoordinator: true,
    });
    const response = await listProviderClassroomsV2(apiParam);
    return buildResponse(response, fieldsRequestedForAggregation);
};

/**
 * Query classroom list by instructor.
 * User must be an instructor for the requested provider.
 * Supports filter, sorting and pagination.
 * Query can request fields to be aggregated.  Aggregating a field means grouping the different values
 * for the given field and counting the occurrence of each value. Aggregations are used as auto suggestions
 * for filters (i.e. courseTitle aggregation is used to show as autosuggestion the possible values for course title
 * when filtering).
 */
export const queryClassroomListByInstructor = async ({
    providerArn,
    pageNumber,
    pageSize,
    sortByColumn,
    sortDescending,
    filterOptions,
    fieldsRequestedForAggregation,
}) => {
    const apiParam = getApiParam({
        providerArn,
        pageNumber,
        pageSize,
        sortByColumn,
        sortDescending,
        filterOptions,
        fieldsRequestedForAggregation,
        isCoordinator: false,
    });
    const response = await listInstructorClassroomsV2(apiParam);
    return buildResponse(response, fieldsRequestedForAggregation);
};

const buildResponse = (apiResponse, fieldsRequestedForAggregation) => {
    const classroomList = convertToClassroomList(apiResponse.classrooms);
    const filterValueAggregation = convertAggregations(
        apiResponse.aggregations,
        fieldsRequestedForAggregation
    );
    const { hitCount, isHitCountExact } = convertCountMetadata(apiResponse);
    return {
        classroomList,
        filterValueAggregation,
        hitCount,
        isHitCountExact,
    };
};

/**
 * Converts API classrooms objects into model supported by ClassroomListingTableV2.
 * Input API classrooms shape is:
 * classrooms: [
 *   {
 *       classroomArn: 'String!',
 *       courseId: 'String!',
 *       courseTitle: 'String!',
 *       createdByEmail: 'String'
 *       endsOn: 'Int!',
 *       instructorEmails: [String],
 *       langLocale: 'String!',
 *       locationData: {
 *           displayName: 'String',
 *           latitude: 'Float',
 *           locationType: 'physical|virtual!',
 *           longitude: 'Float',
 *           physicalAddress: {
 *               addressLine1: 'String!',
 *               addressLine2: 'String',
 *               city: 'String!',
 *               country: 'String!',
 *               postalCode: 'String!',
 *               state: 'String!',
 *           },
 *           timezone: 'String!',
 *           virtualUrl: 'String',
 *       },
 *       providerArn: 'String!',
 *       startsOn: 'Int!'
 *   }
 * ]
 * @param classrooms array of classroom from backend API.
 */
const convertToClassroomList = classrooms => {
    if (!classrooms) {
        return [];
    }

    return classrooms.map(apiClassroom => {
        return {
            classroomArn: apiClassroom.classroomArn,
            classroomId: apiClassroom.classroomArn.split('/').pop(),
            courseId: apiClassroom.courseId,
            providerArn: apiClassroom.providerArn,
            [COLUMN_IDS.startDate]: moment.tz(
                apiClassroom.startsOn * 1000,
                apiClassroom.locationData.timezone
            ),
            [COLUMN_IDS.endDate]: moment.tz(
                apiClassroom.endsOn * 1000,
                apiClassroom.locationData.timezone
            ),
            [COLUMN_IDS.country]: apiClassroom.locationData?.physicalAddress?.country,
            [COLUMN_IDS.courseTitle]: apiClassroom.courseTitle,
            [COLUMN_IDS.createdBy]: apiClassroom.createdByEmail,
            [COLUMN_IDS.deliveryMethod]: apiClassroom.locationData.locationType,
            [COLUMN_IDS.instructor]: apiClassroom.instructorEmails,
            locationData: apiClassroom.locationData,
        };
    });
};

/**
 * Converts value aggregations from backend API to filter options values
 * supported by ClassroomListFilter.
 *
 * Shape of API aggregation
 * {
 *    country: {
 *         items: [
 *             {
 *                 value: 'String!',
 *                 count: 'Int!'
 *             }
 *         ],
 *         countType: 'exact|at_least'
 *     },
 *     courseTitle: {
 *         items: [
 *             {
 *                 value: 'String!',
 *                 count: 'Int!'
 *             }
 *         ],
 *         countType: 'exact|at_least'
 *     },
 *     langLocale: {
 *         items: [
 *             {
 *                 value: 'String!',
 *                 count: 'Int!'
 *             }
 *         ],
 *         countType: 'exact|at_least'
 *     }
 * }
 *
 * @param apiAggregations - aggregation from backend API.
 * @param fieldsRequestedForAggregation - original request fields specifying fields to be aggregated
 */
const convertAggregations = (apiAggregations, fieldsRequestedForAggregation) => {
    const convertedAggregation = {};
    fieldsRequestedForAggregation.forEach(fieldRequest => {
        if (apiAggregations[fieldRequest] && apiAggregations[fieldRequest].items) {
            convertedAggregation[fieldRequest] = apiAggregations[fieldRequest].items.map(item => {
                return { ...item };
            });
        }
    });
    return convertedAggregation;
};

/**
 * Converts API response to count metadata supported by ClassroomListingTableV2
 * @param apiResponse
 */
const convertCountMetadata = apiResponse => {
    return {
        hitCount: apiResponse.count,
        isHitCountExact: apiResponse.countType === 'exact',
    };
};

/**
 * getApi* functions convert view layer models to API models.
 */
const getApiParam = ({
    providerArn,
    pageNumber,
    pageSize,
    sortByColumn,
    sortDescending,
    filterOptions,
    fieldsRequestedForAggregation,
    isCoordinator,
}) => {
    const columnToAttributeMap = {
        [COLUMN_IDS.startDate]: 'startsOn',
        [COLUMN_IDS.endDate]: 'endsOn',
        [COLUMN_IDS.courseTitle]: 'courseTitle',
    };

    const apiParam = {
        providerArn,
        filterOptions: getApiFilterOption(filterOptions, isCoordinator),
        paginationOptions: getApiPaginationOption(pageNumber, pageSize),
        sortOptions: {
            ascOrder: !sortDescending,
            attribute: columnToAttributeMap[sortByColumn],
        },
        aggregations: fieldsRequestedForAggregation,
    };

    return apiParam;
};

const getApiPaginationOption = (pageNumber, pageSize) => {
    return {
        fromItemIndex: (pageNumber - 1) * pageSize,
        pageSize,
    };
};

const getApiFilterOption = (domainFilterOption, isCoordinator) => {
    const apiFilterOption = {
        classStartDate: toApiDateRange(domainFilterOption[COLUMN_IDS.startDate]),
        classEndDate: toApiDateRange(domainFilterOption[COLUMN_IDS.endDate]),
    };

    if (domainFilterOption[COLUMN_IDS.country]?.length) {
        apiFilterOption['country'] = domainFilterOption[COLUMN_IDS.country];
    }
    if (domainFilterOption[COLUMN_IDS.courseTitle]?.length) {
        apiFilterOption['courseTitle'] = domainFilterOption[COLUMN_IDS.courseTitle];
    }
    if (domainFilterOption[COLUMN_IDS.createdBy]?.length) {
        apiFilterOption['createdByEmail'] = domainFilterOption[COLUMN_IDS.createdBy];
    }
    if (isCoordinator && domainFilterOption[COLUMN_IDS.instructor]?.length) {
        apiFilterOption.instructorEmail = domainFilterOption[COLUMN_IDS.instructor];
    }
    return apiFilterOption;
};

const toApiDateRange = domainDateRange => {
    if (!domainDateRange) {
        return;
    }
    const apiDateRange = {};
    if (domainDateRange.after) {
        apiDateRange.after = toUTCMillis(domainDateRange.after);
    }
    if (domainDateRange.before) {
        apiDateRange.before = toUTCMillis(domainDateRange.before);
    }
    return apiDateRange;
};

const toUTCMillis = localMoment => {
    if (!localMoment) {
        return null;
    }
    return localMoment.utc().unix();
};
