import { defineMessages } from 'react-intl.macro';

const ingressTableMessages = defineMessages({
    modalHeader: {
        id: 'jamCancelWarning.modalHeader',
        defaultMessage: 'Cancel the Jam session',
    },
    modalBody: {
        id: 'jamCancelWarning.modalBody',
        defaultMessage:
            'This class has been canceled, but the Jam session(s) need to canceled manually through the Jam portal.',
    },
    maybeLaterBtn: {
        id: 'jamCancelWarning.maybeLaterBtn',
        defaultMessage: 'Maybe later',
    },
    cancelJamBtn: {
        id: 'jamCancelWarning.cancelJamBtn',
        defaultMessage: 'Cancel Jam session',
    },
});

export default ingressTableMessages;
