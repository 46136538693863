import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { queryMockClassrooms } from '../../data/classListV2.mock';
import { useLocation } from 'react-router-dom';

import { useFlags } from 'utils/flags';
import {
    queryClassroomListByProvider,
    queryClassroomListByInstructor,
} from './classroomListProvider';
import { useUserInfo } from '../../utils';

/**
 *
 * filterOption: list of filter values calle is from ClassListingTableV2.getInitialState
 *
 * @param providerArn
 * @param pageNumber
 * @param pageSize
 * @param sortByColumn
 * @param sortDescending
 * @param archivedOnly
 * @param filterOptions - filter to refine the query
 * @param filterFieldsRequestedForAggregation - if present, requests the backend API to aggregate these fields for autocomplete feature.
 * @returns {{isLoading: boolean, totalClassroomCount: *, classroomList: ([]|*), isTotalClassroomCountExact: (boolean|*), filterValueAggregation: *}}
 */
export function useClassroomQuery({
    providerArn,
    pageNumber,
    pageSize,
    sortByColumn,
    sortDescending,
    filterOptions,
    filterFieldsRequestedForAggregation = [],
    isLoadingUserInfo,
}) {
    const operation = 'listClassroom';
    const sortByAttribute = sortByColumn.sortingField;
    const useQueryOptions = {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        staleTime: 0,
        cacheTime: 10 * 1000, //one minute
        timeout: 10000,
    };

    const queryFn = useClassroomProvider();
    const { data, isLoading, isPreviousData, isSuccess, refetch } = useQuery(
        [
            isLoadingUserInfo,
            operation,
            providerArn,
            pageNumber,
            pageSize,
            sortByAttribute,
            sortDescending,
            filterOptions,
            filterFieldsRequestedForAggregation,
        ],
        () => {
            if (!isLoadingUserInfo) {
                return queryFn({
                    providerArn,
                    pageNumber,
                    pageSize,
                    sortByColumn: sortByAttribute,
                    sortDescending,
                    filterOptions,
                    fieldsRequestedForAggregation: filterFieldsRequestedForAggregation,
                });
            } else {
                return null;
            }
        },
        useQueryOptions
    );
    return {
        classroomList: data?.classroomList,
        isLoading: isLoading || isPreviousData,
        totalClassroomCount: data?.hitCount,
        isTotalClassroomCountExact: data?.isHitCountExact,
        filterValueAggregation: data?.filterValueAggregation,
        classroomListRefetcher: refetch,
        isSuccess,
    };
}

/**
 * Hook that makes it possible to use a query provider that relies on mocks (i.e. class listing using mock classrooms) for
 * ease of testing/development.
 * Use of mock classrooms is enabled only when two conditions are met:  feature flag 'enableClassListingWithMock' is true
 * and request parameter useMock (i.e. https://amazon.com?useMock=true) is true.
 * @returns {function(*): {hitCount: number, isHitCountExact: boolean, classroomList: []}}
 */
function useClassroomProvider() {
    const features = useFlags();
    const { search } = useLocation();
    const { userIsTrainingCoordinator } = useUserInfo();

    return useMemo(() => {
        // placeholder provider until actual backend function is implemented.
        let queryFn = userIsTrainingCoordinator
            ? queryClassroomListByProvider
            : queryClassroomListByInstructor;
        if (features.enableClassListingWithMock) {
            const urlParams = new URLSearchParams(search);
            const useMockParamValue = urlParams.get('useMock');
            if (useMockParamValue === 'true') {
                queryFn = queryMockClassrooms;
            }
        }
        return queryFn;
    }, [search, features, userIsTrainingCoordinator]);
}
