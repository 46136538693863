import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    manageLabsBtnLabel: {
        id: 'labCard.btn.label',
        defaultMessage: 'Manage Student Labs',
    },
});

export default messages;
