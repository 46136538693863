import { defineMessages } from 'react-intl.macro';
export const messages = defineMessages({
    teamSizeLabel: {
        id: 'classForm.jamDetails.teamSizeLabel',
        defaultMessage: 'Team size',
    },
    teamSizeValueFormat: {
        id: 'classDetails.jamDetails.teamSizeValueFormat',
        defaultMessage: '{num} participants per team',
    },
    liveEventUrlLabel: {
        id: 'classDetails.jamDetails.liveEventUrlLabel',
        defaultMessage: 'Live Event',
    },
    liveEventUrlDescription: {
        id: 'classDetails.jamDetails.liveEventUrlDescription',
        defaultMessage: 'this is the URL students will access',
    },
    testEventUrlLabel: {
        id: 'classDetails.jamDetails.testEventUrlLabel',
        defaultMessage: 'Test event',
    },
    testEventUrlDescription: {
        id: 'classDetails.jamDetails.testEventUrlDescription',
        defaultMessage: 'use this to test the live event',
    },
    adminConsoleUrlLabel: {
        id: 'classDetails.jamDetails.adminConsoleUrlLabel',
        defaultMessage: 'AWS Jam admin console',
    },
});

export default messages;
