import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    jamCardLabel: {
        id: 'jamCard.label',
        defaultMessage: 'AWS Jam Event',
    },
    jamCardDescription: {
        id: 'jamCard.description',
        defaultMessage:
            'In this event, you are going to put your AWS skills to the test by solving various challenges.',
    },
    jamCardLinkLabel: {
        id: 'jamCard.link.label',
        defaultMessage: 'AWS Jam URL',
    },
});

export default messages;
