import messages from './IngressTable.messages';

export const isConsoleReady = labStatus => labStatus === 'CONSOLE_AVAILABLE';

export const computeTableItems = (studentTrainings = [], labId) => {
    let accumulator = {};
    const NO_LAB_STATE = 'NO_LAB';
    for (let i = 0; i < studentTrainings.length; i++) {
        let {
            userKey,
            studentNumber,
            arn,
            lastUpdated,
            firstName,
            lastName,
            metaData,
        } = studentTrainings[i];
        if (!accumulator[userKey]) {
            // no previous item found
            if (arn !== labId) {
                accumulator[userKey] = {
                    userKey,
                    studentNumber,
                    state: NO_LAB_STATE,
                };
                if (firstName && lastName) {
                    accumulator[userKey].firstName = firstName;
                    accumulator[userKey].lastName = lastName;
                }
            } else {
                accumulator[userKey] = studentTrainings[i];
                if (metaData?.labRegions?.length) {
                    accumulator[userKey].labRegion = metaData.labRegions[0];
                }
            }
        } else {
            const shouldReplaceWithItemInAcummulator =
                arn === labId &&
                (accumulator[userKey].state === NO_LAB_STATE ||
                    accumulator[userKey].lastUpdated < lastUpdated);
            if (shouldReplaceWithItemInAcummulator) {
                accumulator[userKey] = studentTrainings[i];
                if (metaData?.labRegions?.length) {
                    accumulator[userKey].labRegion = metaData.labRegions[0];
                }
            }
        }
    }
    return Object.values(accumulator);
};

export const hiddenAlertBannerItems = {
    visible: false,
};

export const allAlertBannerItems = (formatMessage, alertBannerItemsSet) => {
    return {
        errorAlertBannerItems: {
            type: 'error',
            content: formatMessage(messages.preloadFail),
            dismissible: true,
            dismissAriaLabel: formatMessage(messages.preloadDismiss),
            onDismiss: () => alertBannerItemsSet(hiddenAlertBannerItems),
        },
        hasNotStartedAlertBannerItems: {
            type: 'error',
            content: formatMessage(messages.preloadClassNotStarted),
            dismissible: true,
            dismissAriaLabel: formatMessage(messages.preloadDismiss),
            onDismiss: () => alertBannerItemsSet(hiddenAlertBannerItems),
        },
        successAlertBannerItems: {
            type: 'success',
            content: formatMessage(messages.preloadSuccess),
            dismissible: true,
            dismissAriaLabel: formatMessage(messages.preloadDismiss),
            onDismiss: () => alertBannerItemsSet(hiddenAlertBannerItems),
        },
        inProgressAlertBannerItems: {
            type: 'info',
            content: formatMessage(messages.preloadInProgress),
            dismissible: true,
            dismissAriaLabel: formatMessage(messages.preloadDismiss),
            onDismiss: () => alertBannerItemsSet(hiddenAlertBannerItems),
        },
    };
};

export const buildResetBannerItem = (formatMessage, setBanner, region, labNumber) => {
    return {
        type: 'warning',
        content: formatMessage(messages.resetRegion, {
            selectedRegion: region.value,
            labNumber: labNumber,
        }),
        dismissible: true,
        dismissAriaLabel: formatMessage(messages.preloadDismiss),
        onDismiss: () => setBanner(hiddenAlertBannerItems),
    };
};

export const LabStatus = Object.freeze({
    NoLab: 'no-lab',
    Preloading: 'provisioning',
    Preloaded: 'preloaded',
    Ended: 'ended',
    Failed: 'failed',
    Unknown: 'unkown',
});

export const PRELOADING_LAB_STATUSES = [
    'PENDING',
    'INITIALIZING',
    'PROVISIONING',
    'RESOURCES_READY',
];

export const getLabStatus = lab => {
    const hasMetaData = !!lab.metaData;
    let labStatus;

    if (lab.state === 'NO_LAB') {
        labStatus = LabStatus.NoLab;
    } else if (lab.fulfillmentStatus === 'error') {
        labStatus = LabStatus.Failed;
    } else if (hasMetaData && PRELOADING_LAB_STATUSES.includes(lab.metaData.labStatus)) {
        labStatus = LabStatus.Preloading;
    } else if (hasMetaData && lab.metaData.labStatus === 'CONSOLE_AVAILABLE') {
        labStatus = LabStatus.Preloaded;
    } else if (hasMetaData && lab.metaData.labStatus === 'SESSION_ENDED') {
        labStatus = LabStatus.Ended;
    } else {
        labStatus = LabStatus.Unknown;
    }

    return labStatus;
};
