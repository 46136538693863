import React from 'react';
import { useParams } from 'react-router-dom';
import { Icon } from '@amzn/awsui-components-react';

import { LabToggle } from 'components';
import './CardContainer.css';

const CardContainer = ({ title, contentId, duration, description, index, children }) => {
    const { classroomId } = useParams();

    return (
        <li className="card-container" data-testid={`card-container-${index}`}>
            <div className="card-container__header">
                <h3>{title}</h3>
                {duration ? (
                    <span>
                        <Icon name="status-pending" variant="subtle" data-testid="duration-icon" />
                        &nbsp; {duration}
                    </span>
                ) : null}
            </div>
            <p>{description}</p>
            <hr />
            <div className="card-container__actions">
                {children}
                <div className="card-container__toggle">
                    <LabToggle labArn={contentId} classroomId={classroomId} />
                </div>
            </div>
        </li>
    );
};

export default CardContainer;
