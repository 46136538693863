import React, { createContext, useReducer, useCallback } from 'react';
import { useLocale } from 'utils';

const LOAD_INITIAL_STATE = 'LOAD_INITIAL_STATE';
const UPDATE_TRAINING_PERMISSIONS = 'UPDATE_TRAINING_PERMISSIONS';
const UPDATE_STUDENT_ROSTER = 'UPDATE_STUDENT_ROSTER';
const DELETE_FROM_STUDENT_ROSTER = 'DELETE_FROM_STUDENT_ROSTER';
const UPDATE_CLASSROOM = 'UPDATE_CLASSROOM';
const ClassroomContext = createContext();

const reducer = (state, action) => {
    const { type, payload } = action;
    if (type === LOAD_INITIAL_STATE) {
        return payload;
    }
    if (type === UPDATE_TRAINING_PERMISSIONS) {
        /**
         * Validate that the payload classroomID and the state classroomId represent the same class
         * This issue occurs when an instructor has multiple classes open with the same course template
         * TODO - research filtering subscriptions on the BFF
         * https://docs.amplify.aws/cli/graphql-transformer/examples#filter-subscriptions-by-model-fields-andor-relations
         */
        if (payload.onUpdateTrainingPermissions.classroomId !== state.classroom.classroomId) {
            return { ...state, content: state.content };
        }
        const {
            onUpdateTrainingPermissions: { activeTrainings },
        } = payload;
        const content = state.content.map(training => ({
            ...training,
            isActive: activeTrainings ? activeTrainings.indexOf(training.contentId) > -1 : false,
        }));
        const jamTrainings = {
            trainings: (state.jamTrainings?.trainings || []).map(training => ({
                ...training,
                isActive: activeTrainings
                    ? activeTrainings.indexOf(training.contentId) > -1
                    : false,
            })),
        };
        return { ...state, content, jamTrainings };
    }
    if (type === UPDATE_STUDENT_ROSTER) {
        const studentRoster = Array.from(payload);
        return {
            ...state,
            classroom: {
                ...state.classroom,
                studentRoster,
            },
        };
    }
    if (type === DELETE_FROM_STUDENT_ROSTER) {
        const studentRoster = state.classroom.studentRoster.filter(user => user.email !== payload);
        return {
            ...state,
            classroom: {
                ...state.classroom,
                studentRoster,
            },
        };
    }
    if (type === UPDATE_CLASSROOM) {
        return {
            ...state,
            classroom: {
                ...state.classroom,
                ...payload,
            },
        };
    }
    return state;
};

const ClassroomProvider = ({ children, value = {} }) => {
    const [classData, dispatch] = useReducer(reducer, value);
    const [, localeSet] = useLocale();
    const loadInitialState = useCallback(
        payload => {
            dispatch({ type: LOAD_INITIAL_STATE, payload });
            localeSet({ locale: payload.course.langLocale });
        },
        [dispatch, localeSet]
    );
    const updateTrainingPermissions = useCallback(
        payload => {
            dispatch({ type: UPDATE_TRAINING_PERMISSIONS, payload });
        },
        [dispatch]
    );

    const updateStudentRoster = useCallback(
        payload => {
            dispatch({
                type: UPDATE_STUDENT_ROSTER,
                payload,
            });
        },
        [dispatch]
    );

    const deleteFromStudentRoster = useCallback(
        payload => {
            dispatch({
                type: DELETE_FROM_STUDENT_ROSTER,
                payload,
            });
        },
        [dispatch]
    );

    const updateClassroomData = useCallback(
        payload => {
            dispatch({
                type: UPDATE_CLASSROOM,
                payload,
            });
        },
        [dispatch]
    );

    const initialValue = {
        classData,
        loadInitialState,
        updateTrainingPermissions,
        updateStudentRoster,
        deleteFromStudentRoster,
        updateClassroomData,
    };
    return <ClassroomContext.Provider value={initialValue}>{children}</ClassroomContext.Provider>;
};

export { LOAD_INITIAL_STATE, ClassroomContext, ClassroomProvider };
