import Amplify, { Auth, Cache } from 'aws-amplify';
import environment from '../.config/environment';
import { isLocalhost } from '../utils/helpers';

const {
    region,
    domains,
    userPoolId,
    clientId,
    identityPoolId,
    hostName: { instructorGql },
    LOG_LEVEL,
} = environment(window);

/**
 * Enable Amplify debugging in localhost for better transparency
 */
if (isLocalhost) {
    window.LOG_LEVEL = LOG_LEVEL;
}

export const createUrlInterceptor = ({ issuer, login, logout }) => url => {
    const SELF = '_self';
    const loginRequestUrl = `https://${issuer}/oauth2/authorize`;
    const logoutRequestUrl = `https://${issuer}/logout`;
    let windowProxy;

    if (login && url.startsWith(loginRequestUrl)) {
        const signInUrl = url.replace(loginRequestUrl, `https://${login}/login`);
        windowProxy = window.open(signInUrl, SELF);
    } else if (logout && url.startsWith(logoutRequestUrl)) {
        const signOutUrl = url.replace(logoutRequestUrl, `https://${logout}/logout`);
        windowProxy = window.open(signOutUrl, SELF);
    } else {
        windowProxy = window.open(url, SELF);
    }
    return windowProxy ? Promise.resolve(windowProxy) : Promise.reject();
};

const AMPLIFY_CONFIG = {
    Auth: {
        region,
        identityPoolId,
        userPoolId: userPoolId,
        userPoolWebClientId: clientId,
        oauth: {
            domain: domains.issuer,
            scope: ['openid'],
            redirectSignIn: `${window.location.origin}/callback`,
            redirectSignOut: `${window.location.origin}/providers`,
            responseType: 'code',
            codeChallengeMethod: 'S256',
            codeChallenge: encodeURI(btoa(Math.random())),
            urlOpener: createUrlInterceptor(domains),
        },
    },
    API: {
        aws_appsync_graphqlEndpoint: instructorGql,
        aws_appsync_region: region,
        aws_appsync_authenticationType: 'OPENID_CONNECT',
        graphql_headers: async () => ({
            Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
        }),
    },
};

/**
 * Redirects to the Cognito login page.
 * NOTE: redirec_url must match callback url defined in cognito has to
 * match one of the callback URL's defined in the Cognito console. Since we lose the class path
 * we store here to redirect after the Cognito redirect
 */
function redirectToLoginPage() {
    sessionStorage.setItem('redirect_pathname', window.location.pathname);
    configureAmplify();
    Auth.federatedSignIn();
}

/**
 * Checks if there is a currrent authenticated user.
 * Due to Amplify expecting OIDC to be set by authenticating with identity pools we must
 * manage federatedInfo here. Auth.currentAuthenticatedUser returns a valid jwtToken and if different
 * from Cache federatedInfo, update the cache with the valid token
 */
async function hasValidToken() {
    try {
        const cognitoIdToken = (await Auth.currentAuthenticatedUser({ bypassCache: true }))
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken();
        const currentToken = Cache.getItem('federatedInfo').token;
        if (currentToken !== cognitoIdToken) {
            Cache.setItem('federatedInfo', { token: cognitoIdToken });
        }
        return true;
    } catch (e) {
        return false;
    }
}

function configureAmplify() {
    Amplify.configure(AMPLIFY_CONFIG);
}

export { AMPLIFY_CONFIG, hasValidToken, redirectToLoginPage, configureAmplify };
