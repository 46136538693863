import { COLUMN_IDS } from './classlistTableConfig';
import { messages } from '../classesTable/ClassTable.messages';

/**
 * Logic and configuration to enable Table property filter v2.1. (see https://v2-1.polaris.a2z.com/components/awsui-table-property-filtering/)
 * See propertyFilterV3Support to enable v3.0
 */
export const getHandlers = baseHandler => {
    return {
        ...baseHandler,

        /**
         * event is
         * {
         *     detail: {
         *         propertyKey: 'courseTitle'.
         *         value: 'Course title'
         *     }
         * }
         * @param event
         */
        filterPropertyChangeHandler: event => {
            //no op
        },

        filterInputChangeHandler: event => {
            //no op
        },

        getInitialState: () => {
            return {
                tokens: [],
                filteringText: '',
            };
        },

        /**
         * Converts filter options values to the format required by property filter's filteringOptions
         * filterValueOptions input format:
         *
         *     attributeName: [
         *         {
         *             value: string,
         *             count: number
         *         }
         *     ]
         * }
         * converted into:
         * [
         *  {
         *      propertyKey: string, //attributeName
         *      propertyLabel: string,
         *      values: string[],
         *      group: string, //optional
         *      groupValuesLabel
         *  }
         * ]
         *
         * @param filterValueOptions
         */
        filterOptionValuesAdapter: (uiConfig, filterValueOptions) => {
            const { filterFields } = uiConfig;
            const convertedFilterOptions = [];
            for (const attributeName in filterValueOptions) {
                const tempOptionValues = filterValueOptions[attributeName].map(
                    filterOptionValue => filterOptionValue.value
                );
                const singleAttributeFilterOptions = {
                    propertyKey: attributeName,
                    propertyLabel: filterFields[attributeName].propertyLabel,
                    values: tempOptionValues,
                };
                convertedFilterOptions.push(singleAttributeFilterOptions);
            }

            //Add additional fields to filter option
            for (const attribute of [
                COLUMN_IDS.endDate,
                COLUMN_IDS.createdBy,
                COLUMN_IDS.instructor,
            ]) {
                if (filterFields[attribute]) {
                    convertedFilterOptions.push({
                        propertyKey: attribute,
                        propertyLabel: filterFields[attribute].propertyLabel,
                    });
                }
            }

            return convertedFilterOptions;
        },
    };
};

export const getPropertyFilterConfiguration = ({
    intl,
    isUserTrainingCoordinator,
    filterConfig,
}) => {
    const { formatMessage } = intl;
    const filteringProperties = {
        [COLUMN_IDS.courseTitle]: {
            propertyLabel: formatMessage(messages.courseTitle),
        },
        [COLUMN_IDS.endDate]: {
            propertyLabel: formatMessage(messages.endsOnDate),
        },
        [COLUMN_IDS.country]: {
            propertyLabel: formatMessage(messages.country),
        },
        [COLUMN_IDS.createdBy]: {
            propertyLabel: formatMessage(messages.createdBy),
        },
    };

    if (isUserTrainingCoordinator) {
        filteringProperties[COLUMN_IDS.instructor] = {
            propertyLabel: formatMessage(messages.instructorList),
        };
    }

    return filteringProperties;
};

export const getPropertyFilterI18nStrings = (intl, archivedMode) => {
    const { formatMessage } = intl;
    return {
        placeholderText: formatMessage(
            archivedMode ? messages.filterPlaceholderForArchived : messages.filterPlaceholder
        ),
        operationAndText: formatMessage(messages.filterOperationAnd),
        operationNotAndText: formatMessage(messages.filterOperationNotAnd),
        operationOrText: formatMessage(messages.filterOperationOr),
        operationNotOrText: formatMessage(messages.filterOperationNotOr),
        clearFiltersText: formatMessage(messages.filterClear),
        groupPropertiesText: formatMessage(messages.filterGroupProperty),
        groupValuesText: formatMessage(messages.filterGroupValue),
        filteringEmpty: formatMessage(messages.filterEmptyResult),
        filteringLabel: formatMessage(messages.filterLabel),
        filteringStatusText: formatMessage(messages.filterStatusText),
        filteredResultCountText: resultsCount =>
            formatMessage(messages.filterResultCount, { resultsCount }),
        removeTokenLabel: token =>
            formatMessage(messages.filterRemoveToken, {
                filterProperty: token.propertyLabel,
                filterValue: token.value,
            }),
    };
};
