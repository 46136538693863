import { Children } from 'react';

/**
 * Contains a widget within a description list.
 * @param title - the title of the widget
 * @param className - styles to apply to the description list
 * @param children - children elements which will be list items
 */
const WidgetContainer = props => {
    const { title, children, className } = props;
    return (
        <dl className={className} data-testid={props['data-testid']}>
            <dt className="awsui-util-label">{title}</dt>
            {Children.map(children, child => (
                <dd>{child}</dd>
            ))}
        </dl>
    );
};

export default WidgetContainer;
