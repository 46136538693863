import { clientLog } from '../graphql';
import { executeRequest } from '../modules';
import { nowDateString } from './helpers';
import { createLogMessage } from './createLogMessage';

export const RUNTIME_ERROR_NAME = 'RuntimeError';
export const FETCH_ERROR_NAME = 'FetchError';
export const AUTH_ERROR = 'AuthError';

export const recordError = async ({ message, lineno, colno, type, ...rest }) => {
    await executeRequest({
        operation: clientLog,
        params: {
            input: {
                body: JSON.stringify(
                    await createLogMessage({
                        payload: {
                            type,
                            attributes: {
                                message,
                                ...(colno ? { colno } : {}),
                                ...(lineno ? { lineno } : {}),
                                ...rest,
                            },
                        },
                    })
                ),
            },
        },
    });
};

export class AppError extends Error {
    constructor(message, params) {
        super(message.replace(/Error:\s/, ''));

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AppError);
        }
        recordError({
            message,
            lineno: this.lineNumber,
            colno: this.columnNumber,
            type: params.type,
            date: nowDateString(),
            ...params,
        });
    }
}

export class APIException extends AppError {
    constructor({ statusCode, path }) {
        super(`operation ${path} returned a ${statusCode}`, {
            type: FETCH_ERROR_NAME,
            status: statusCode,
        });
        this.statusCode = statusCode;
        this.path = path;
    }
}

export const createException = (statusCode, path) => {
    const toThrow = { statusCode, path };
    return new APIException(toThrow);
};
