import React, { Fragment, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ColumnLayout, FormField, Input, FormSection } from '@amzn/awsui-components-react';
import FormFieldV3 from '@amzn/awsui-components-react-v3/polaris/form-field';
import RadioGroup from '@amzn/awsui-components-react-v3/polaris/radio-group';
import Select from '@amzn/awsui-components-react-v3/polaris/select';

import { messages } from '../ClassForm.messages';
import {
    LOCATION_TYPES,
    handleFormValueChange,
    useLocationTypeLabel,
    prepareCountriesForSelect,
} from '../ClassForm.utils';
import { FieldErrors } from '../FieldErrors';
import '../ClassForm.css';

const ClassLocation = ({
    setClassData,
    locationType,
    virtualUrl,
    addressLine1,
    addressLine2,
    city,
    state,
    postalCode,
    country,
    fieldsInvalid,
    handleFormSubmit,
}) => {
    const { formatMessage } = useIntl();
    const countryOptions = useMemo(() => prepareCountriesForSelect(formatMessage), [formatMessage]);
    const virtualLocationLabel = useLocationTypeLabel(LOCATION_TYPES.virtual);
    const physicalLocationLabel = useLocationTypeLabel(LOCATION_TYPES.physical);

    const getOptionForCountry = country => countryOptions.find(o => o.id === country);

    const handleKeyboardSubmit = e => {
        if (e.detail.key === 'Enter') handleFormSubmit(e);
    };

    return (
        <FormSection id="class-location" header={formatMessage(messages.classLocationHeader)}>
            <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                    <FormFieldV3>
                        <RadioGroup
                            value={locationType}
                            onChange={e => {
                                handleFormValueChange({
                                    value: e.detail.value,
                                    setData: setClassData,
                                    keyPath: 'locationType',
                                });
                            }}
                            items={[
                                {
                                    value: LOCATION_TYPES.virtual,
                                    label: virtualLocationLabel,
                                },
                                {
                                    value: LOCATION_TYPES.physical,
                                    label: physicalLocationLabel,
                                },
                            ]}
                            ariaRequired
                            id="ClassForm__locationType"
                        ></RadioGroup>
                    </FormFieldV3>
                    {locationType === LOCATION_TYPES.virtual ? (
                        <FormField
                            stretch
                            description={formatMessage(messages.virtualDescription)}
                            label={formatMessage(messages.virtualClassUrlLabel)}
                        >
                            <div className="ClassForm__virtual">
                                <Input
                                    ariaRequired
                                    value={virtualUrl}
                                    invalid={!!fieldsInvalid.virtualUrl}
                                    onInput={e =>
                                        handleFormValueChange({
                                            value: e.detail.value,
                                            setData: setClassData,
                                            keyPath: 'virtualUrl',
                                        })
                                    }
                                    id="ClassForm__virtualUrl"
                                    placeholder="https://aws.training"
                                    onKeypress={handleKeyboardSubmit}
                                ></Input>
                                <FieldErrors fieldsInvalid={fieldsInvalid} property="virtualUrl" />
                            </div>
                        </FormField>
                    ) : null}
                    {locationType === LOCATION_TYPES.physical ? (
                        <Fragment>
                            <ColumnLayout>
                                <div data-awsui-column-layout-root="true">
                                    <FormField
                                        stretch
                                        label={formatMessage(messages.addressLine1Label)}
                                    >
                                        <Input
                                            ariaRequired
                                            id="addressSection__addressLine1"
                                            invalid={!!fieldsInvalid.addressLine1}
                                            value={addressLine1}
                                            onInput={e =>
                                                handleFormValueChange({
                                                    value: e.detail.value,
                                                    setData: setClassData,
                                                    keyPath: 'addressLine1',
                                                })
                                            }
                                        />
                                        <FieldErrors
                                            fieldsInvalid={fieldsInvalid}
                                            property="addressLine1"
                                        />
                                    </FormField>
                                    <FormField
                                        stretch
                                        label={formatMessage(messages.addressLine2Label)}
                                    >
                                        <Input
                                            id="addressSection__addressLine2"
                                            value={addressLine2}
                                            onInput={e =>
                                                handleFormValueChange({
                                                    value: e.detail.value,
                                                    setData: setClassData,
                                                    keyPath: 'addressLine2',
                                                })
                                            }
                                        />
                                        <FieldErrors
                                            fieldsInvalid={fieldsInvalid}
                                            property="addressLine2"
                                        />
                                    </FormField>
                                </div>
                            </ColumnLayout>
                            <ColumnLayout columns={2}>
                                <div data-awsui-column-layout-root="true">
                                    <FormField stretch label={formatMessage(messages.cityLabel)}>
                                        <Input
                                            ariaRequired
                                            id="addressSection__city"
                                            value={city}
                                            invalid={!!fieldsInvalid.city}
                                            onInput={e =>
                                                handleFormValueChange({
                                                    value: e.detail.value,
                                                    setData: setClassData,
                                                    keyPath: 'city',
                                                })
                                            }
                                        />
                                        <FieldErrors
                                            fieldsInvalid={fieldsInvalid}
                                            property="city"
                                        />
                                    </FormField>
                                    <FormField stretch label={formatMessage(messages.stateLabel)}>
                                        <Input
                                            ariaRequired
                                            id="addressSection__state"
                                            invalid={!!fieldsInvalid.state}
                                            value={state}
                                            onInput={e =>
                                                handleFormValueChange({
                                                    value: e.detail.value,
                                                    setData: setClassData,
                                                    keyPath: 'state',
                                                })
                                            }
                                        />
                                        <FieldErrors
                                            fieldsInvalid={fieldsInvalid}
                                            property="state"
                                        />
                                    </FormField>
                                    <FormField
                                        stretch
                                        label={formatMessage(messages.postalCodeLabel)}
                                    >
                                        <Input
                                            ariaRequired
                                            id="addressSection__postalCode"
                                            invalid={!!fieldsInvalid.postalCode}
                                            value={postalCode}
                                            onInput={e =>
                                                handleFormValueChange({
                                                    value: e.detail.value,
                                                    setData: setClassData,
                                                    keyPath: 'postalCode',
                                                })
                                            }
                                        />
                                        <FieldErrors
                                            fieldsInvalid={fieldsInvalid}
                                            property="postalCode"
                                        />
                                    </FormField>
                                    <FormFieldV3
                                        stretch
                                        label={formatMessage(messages.countryLabel)}
                                    >
                                        <Select
                                            ariaRequired
                                            id="addressSection__country"
                                            invalid={!!fieldsInvalid.country}
                                            selectedOption={getOptionForCountry(country)}
                                            filteringType="auto"
                                            label={formatMessage(messages.countryPlaceholder)}
                                            options={countryOptions}
                                            placeholder={formatMessage(messages.countryPlaceholder)}
                                            selectedLabel={formatMessage(messages.selectedLabel)}
                                            onChange={e =>
                                                handleFormValueChange({
                                                    value: e.detail.selectedOption.id,
                                                    setData: setClassData,
                                                    keyPath: 'country',
                                                })
                                            }
                                        ></Select>

                                        <FieldErrors
                                            fieldsInvalid={fieldsInvalid}
                                            property="country"
                                        />
                                    </FormFieldV3>
                                </div>
                            </ColumnLayout>
                        </Fragment>
                    ) : null}
                </div>
            </ColumnLayout>
        </FormSection>
    );
};

export default ClassLocation;
